import React, { useState } from 'react'
import styled, { css } from "styled-components";
import Lang from '../../../global/Lang';

// imgs
import CPImg from "../../../../images/mining-returntype/returntype-cp.svg";
import WPImg from "../../../../images/mining-returntype/returntype-wp.svg";
import WMTImg from "../../../../images/mining-returntype/returntype-wmt.svg";
import MAPImg from "../../../../images/mining-returntype/returntype-map.svg";

import CPImgHover from "../../../../images/mining-returntype/returntype-cp-wt.svg";
import WPImgHover from "../../../../images/mining-returntype/returntype-wp-wt.svg";
import WMTImgHover from "../../../../images/mining-returntype/returntype-wmt-wt.svg";
import MAPImgHover from "../../../../images/mining-returntype/returntype-map-wt.svg";

import {
    RWD_SM,
} from "../../../utils/rwd";

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    padding: 16px;

    ${RWD_SM(css`
       grid-template-columns: repeat(1, 1fr);
       padding: 0;
    `)};
    
`;

const CardWrapper = styled.div`
    padding: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: 1px solid #151944;
    border-radius: 8px;
    cursor: pointer;
    background-color: ${(props) =>
        props.isSelected ? "#151944" : "white"};
    color: ${(props) => (props.isSelected ? "white" : "#151944")};

    &:hover {
        color: white;
        background-color: #151944;
    }

    ${RWD_SM(css`
        padding: 8px;
  `)};
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  img {
    width: 56px;
    height: 56px;
  }
`;


const Title = styled.h3`
    font-size: 18px;
    font-weight: bold;

    ${RWD_SM(css`
        font-size: 14px;
  `)};
`;

const Description = styled.p`
    font-size: 14px;
    ${RWD_SM(css`
        font-size: 12px;
  `)};
`;

const NewReturnType = ({
    selectedCondition, // 判斷所選的那個 modal 是否為 packageId 38 - 43
    setReturnType,
    returnType,
    language
}) => {
    const [hoverId, setHoverId] = useState(null);
    const ReturnTypeText = [
        {
            id: 1,
            text: (
                <Lang
                    datakey="mining_return_type_no"
                    defaultText="Compound Package (CP)"
                />
            ),
            image: CPImg,
            hoverImage: CPImgHover,
            description: '本金與產生的Token於當期結算後，一起放入下一期的運作。',
            enDescription: "Both the deposit and the mined Tokens will be included in the next cycle's operation after the current cycle's settlement.",
        },
        {
            id: 2,
            text: (
                <Lang
                    datakey="mining_return_type_all"
                    defaultText="Withdraw Package (WP)"
                />
            ),
            image: WPImg,
            hoverImage: WPImgHover,
            description: '本金與產生的Token於當期結算後，返還到您的地址。',
            enDescription: 'Both the deposit and the mined Tokens will be returned to your address after the current cycle’s settlement.',
        },
        {
            id: 3,
            text: (
                <Lang
                    datakey="mining_return_type_interest"
                    defaultText="Withdraw Mined Token (WMT)"
                />
            ),
            image: WMTImg,
            hoverImage: WMTImgHover,
            description: '產生的Token於當期結算後，返還到您的地址。',
            enDescription: 'The mined Tokens will be returned to your address after the current cycle’s settlement.',
        },
        {
            id: 4,
            text: (
                <Lang
                    datakey="mining_return_type_avapoint"
                    defaultText="Mined to Ava Point (MAP)"
                />
            ),
            image: MAPImg,
            hoverImage: MAPImgHover,
            description: '產生的Token於當期結算後，返還到您的AVA Point。',
            enDescription: 'The mined Tokens will be returned to your AVA Point after the current cycle’s settlement.',
        },
    ];

    const filterId = selectedCondition
        ? ReturnTypeText.filter(option => option.id !== 2)
        : ReturnTypeText

    return (
        <Wrapper>
            {filterId.map((option) => (
                <CardWrapper
                    key={option.id}
                    onClick={() => setReturnType(option.id)}
                    isSelected={returnType === option.id}
                    onMouseEnter={() => setHoverId(option.id)}
                    onMouseLeave={() => setHoverId(null)}
                >
                    <ImageWrapper>
                        <img
                            src={
                                hoverId === option.id || returnType === option.id
                                    ? option.hoverImage
                                    : option.image
                            }
                            alt={`Return Type ${option.id}`}
                        />
                    </ImageWrapper>

                    <Title>{option.text}</Title>
                    <Description>
                        {language === "En" ? option.enDescription : option.description}
                    </Description>
                </CardWrapper>
            ))}
        </Wrapper>

    )
}

export default NewReturnType