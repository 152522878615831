import { createApi } from "@reduxjs/toolkit/query/react";
import { makeUrlencoded } from "./common";
import defaultBaseQuery from "./query/defaultBaseQueryV2";

export const miningApi = createApi({
  reducerPath: "miningApi",
  baseQuery: defaultBaseQuery,
  endpoints: (builder) => ({
    getMining: builder.query({
      query: () => ({
        url: "/mining/GetAccountMinings",
        method: "GET",
      }),
      providesTags: [{ type: "mining", id: "mining" }],
    }),
    getMiningDetail: builder.mutation({
      query: ({ body }) => ({
        url: "/mining/GetMiningDetail",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      // invalidatesTags: [{ type: "mining", id: "mining" }],
    }),
    updateMiningInfo: builder.mutation({
      query: ({ body }) => ({
        url: "/mining/UpdateMiningInfo",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "mining", id: "mining" }],
    }),
    reportMiningTxid: builder.mutation({
      query: ({ body }) => ({
        url: "/mining/ReportTxid",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "mining", id: "mining" }],
    }),
    getAhftReturnGroup: builder.mutation({
      query: () => ({
        url: "/mining/GetAhftReturnGroup",
        method: "GET",
      }),
      invalidatesTags: [{ type: "mining", id: "mining" }],
    }),
    getMiningPackage: builder.query({
      query: () => ({
        url: "/mining/GetMiningPackages",
        method: "GET",
      }),
    }),
    addMining: builder.mutation({
      query: ({ body }) => ({
        url: "/mining/AddMining",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "mining", id: "mining" }],
    }),
    applyTransferPackage: builder.mutation({
      query: ({ body }) => ({
        url: "/mining/ApplyTransferPackage",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "mining", id: "mining" }],
    }),
    cancelWaitingPackage: builder.mutation({
      query: ({ body }) => ({
        url: "/mining/CancelWaitingPackage",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "mining", id: "mining" }],
    }),
    getAutoGridRobotSymbols: builder.query({
      query: (packageId) => ({
        url: `/mining/GetAutoGridRobotSymbols?packageId=${packageId}`,
        method: "GET",
      }),
    }),
    fetchAutoGridRobotHistory: builder.mutation({
      query: ({ AhftGroupId, Month, Asset, HistoryType }) => ({
        url: "/mining/FetchAutoGridRobotHistory",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          AhftGroupId,
          Month,
          Asset,
          HistoryType,
        }),
      }),
    }),
  }),
});

export const {
  useGetMiningQuery,
  useGetMiningDetailMutation,
  useUpdateMiningInfoMutation,
  useReportMiningTxidMutation,
  useGetAhftReturnGroupMutation,
  useGetMiningPackageQuery,
  useAddMiningMutation,
  useApplyTransferPackageMutation,
  useCancelWaitingPackageMutation,
  useGetAutoGridRobotSymbolsQuery,
  useFetchAutoGridRobotHistoryMutation,
} = miningApi;
