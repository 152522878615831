import styled, { css } from "styled-components";
import Icons from "../../../global/icons";
import { useState } from "react";
import { PiEyeLight } from "react-icons/pi";
import { PiEyeSlashLight } from "react-icons/pi";
import { RWD_SM } from "../../../utils/rwd";
import Progress from "./Progress";
import { PasswordCheck } from "../../../utils";

import { handleParseLanguage } from "../../../utils";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const Container = styled.div`
  width: clamp(280px, 40vw, 500px);
  flex: 1;
  display: flex;
  flex-direction: column;

  ${RWD_SM(css`
    width: 85vw;
  `)};
`;

const Title = styled.h2`
  font-size: clamp(22px, 1.822vw, 35px);
`;

const Info = styled.div`
  margin-bottom: 20px;
  font-size: clamp(14px, 0.833vw, 16px);
`;
const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const InputEl = styled.input`
  color: #fcfcfc;
  padding: 10px 10px;
  width: 100%;
  /* background: rgba(255, 255, 255, 0.1); */
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  &::placeholder {
    font-size: clamp(14px, 0.833vw, 16px);
  }
`;

const LabelEl = styled.label`
  /* font-size: 20px; */
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  min-width: 120px;
  border-radius: 60px;

  color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
  background: ${(props) =>
    props.type === "confirm"
      ? props.disabled
        ? "#3A3D5A"
        : "linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%)"
      : ""};
  border-color: ${(props) => (props.disabled ? "#d9d9d9" : "")};
  display: flex;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

const ButtonText = styled.span`
  padding: 10px 16px;
  text-align: center;
  /* font-size: min(max(1.0416vw, 14px), 20px); */
  color: #fcfcfc;
`;

const FormEl = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ProgressList = styled.ul`
  border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
`;

const ProgressItem = styled.li`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
`;

const Conditions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Condition = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: clamp(14px, 0.833vw, 16px);
`;

const ConditionText = styled.span``;

const ConditionIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & path {
    fill: ${(props) => (props.isValid ? "#1cac2a" : "#54587B")};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const ToggleShow = styled.div`
  position: absolute;
  right: 15px;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  font-size: 20px;
`;

const Password = ({
  step,
  stepCount,
  password,
  setPassword,
  handleNextStep,
}) => {
  const [confirmPassword, setConfirmPassword] = useState("");

  const isValid =
    PasswordCheck(password) && confirmPassword && confirmPassword === password;

  const [lengthIsValid, setLengthIsValid] = useState(false);
  const [digitIsValid, setDigitIsValid] = useState(false);
  const [letterIsValid, setLetterIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState("");

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    //  8 - 20 字元
    const regex = /^.{8,20}$/;
    setLengthIsValid(regex.test(value));

    // 至少一個數字
    const digitRegex = /\d/;
    setDigitIsValid(digitRegex.test(value));

    // 至少一個英文字母
    const letterRegex = /[a-zA-Z]/;
    setLetterIsValid(letterRegex.test(value));
  };

  const handleToggleShow = (type) => {
    if (type === "password") setShowPassword(!showPassword);
    if (type === "confirm") setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSetPassword = () => {
    handleNextStep();
  };

  return (
    <>
      <Wrapper>
        <Container>
          <Title>{handleParseLanguage("register_password_title")}</Title>
          <Info>{handleParseLanguage("register_password_directions")}</Info>
          <FormEl>
            <FormContainer>
              <InputWrapper>
                <InputEl
                  type={showPassword ? "text" : "password"}
                  placeholder={handleParseLanguage("password")}
                  value={password}
                  onChange={handlePasswordChange}
                />
                <ToggleShow onClick={() => handleToggleShow("password")}>
                  {showPassword ? <PiEyeSlashLight /> : <PiEyeLight />}
                </ToggleShow>
              </InputWrapper>
              <InputWrapper>
                <InputEl
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  placeholder={handleParseLanguage("confirm_password")}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <ToggleShow onClick={() => handleToggleShow("confirm")}>
                  {showConfirmPassword ? <PiEyeSlashLight /> : <PiEyeLight />}
                </ToggleShow>
              </InputWrapper>
            </FormContainer>
            <Conditions>
              <Condition>
                <ConditionIcon isValid={lengthIsValid}>
                  <Icons.Check />
                </ConditionIcon>
                <ConditionText>
                  {handleParseLanguage("password_rule_character")}
                </ConditionText>
              </Condition>
              <Condition>
                <ConditionIcon isValid={digitIsValid}>
                  <Icons.Check />
                </ConditionIcon>
                <ConditionText>
                  {handleParseLanguage("password_rule_digit")}
                </ConditionText>
              </Condition>
              <Condition>
                <ConditionIcon isValid={letterIsValid}>
                  <Icons.Check />
                </ConditionIcon>
                <ConditionText>
                  {handleParseLanguage("password_rule_letter")}
                </ConditionText>
              </Condition>
            </Conditions>
          </FormEl>
        </Container>
      </Wrapper>
      <Progress
        stepCount={stepCount}
        step={step}
        isValid={isValid}
        handleAction={handleSetPassword}
      />
    </>
  );
};

export default Password;
