import React, { useEffect } from 'react';
import styled from 'styled-components';

const DialogWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  
`;

const DialogContent = styled.div`
  background: #fff;
  border-radius: 8px;
  width: 480px;
  max-width: 90%;
  padding: 40px 20px;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  max-height: 90vh;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 10px;
  background: none;
  border: none;
  font-size: 32px;
  color: #151944;
  cursor: pointer;
  
`;

const WalletDialog = ({ isOpen, onClose, children }) => {

    // 打開 dialog 時，禁止滾動body
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <DialogWrapper onClick={onClose}>
            <DialogContent onClick={(e) => e.stopPropagation()}>
                <CloseButton onClick={onClose}>&times;</CloseButton>
                {children}
            </DialogContent>
        </DialogWrapper>
    );
};

export default WalletDialog;
