import { createApi } from "@reduxjs/toolkit/query/react";
import defaultBaseQuery from "./query/defaultBaseQueryV2";
import { makeUrlencoded } from "./common";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: defaultBaseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ body }) => ({
        url: "/auth/login",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: makeUrlencoded(body),
      }),
    }),

    sendEmail: builder.mutation({
      query: ({ body }) => ({
        url: "/auth/SendMail",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: makeUrlencoded(body),
      }),
    }),

    forgotPassword: builder.mutation({
      query: ({ body }) => ({
        url: "/auth/ForgotPassword",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: makeUrlencoded(body),
      }),
    }),

    verifyEmail: builder.mutation({
      query: ({ body }) => ({
        url: "/auth/VerifyEmail",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: makeUrlencoded(body),
      }),
    }),

    verifyForgotPassword: builder.mutation({
      query: ({ body }) => ({
        url: "/auth/VerifyCodeWithoutAuth",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: makeUrlencoded(body),
      }),
    }),

    getVerifyAccount: builder.mutation({
      query: ({ body }) => ({
        url: "/auth/GetVerifyAccount",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: makeUrlencoded(body),
      }),
    }),

    verifyTelegram: builder.mutation({
      query: ({ body }) => ({
        url: "/auth/VerifyTelegram",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: makeUrlencoded(body),
      }),
    }),

    setPassword: builder.mutation({
      query: ({ body }) => ({
        url: "/auth/SetPassword",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: makeUrlencoded(body),
      }),
    }),

    sendEmailAndTelegram: builder.mutation({
      query: () => ({
        url: "/auth/SendVerifyCode",
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }),
    }),

    verifyWithdrawl: builder.mutation({
      query: ({ body }) => ({
        url: "/auth/VerifyCode",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: makeUrlencoded(body),
      }),
    }),

    verifyCodeForTransfer: builder.mutation({
      query: ({ body }) => ({
        url: "/auth/VerifyCodeForTransfer",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: makeUrlencoded(body),
      }),
    }),

    uploadKyc: builder.mutation({
      async queryFn(form, _queryApi, _extraOptions, fetchWithBQ) {
        // upload with multipart/form-data
        const response = await fetchWithBQ(
          {
            url: "/auth/UploadKyc",
            method: "POST",
            body: form,
          },
          _queryApi,
          _extraOptions
        );
        return response;
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useSendEmailMutation,
  useVerifyEmailMutation,
  useVerifyTelegramMutation,
  useGetVerifyAccountMutation,
  useSetPasswordMutation,
  useForgotPasswordMutation,
  useSendEmailAndTelegramMutation,
  useVerifyWithdrawlMutation,
  useUploadKycMutation,
  useVerifyForgotPasswordMutation,
  useVerifyCodeForTransferMutation,
} = authApi;
