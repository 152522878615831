import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectAccount } from "../../slices/accountSlice";
import {
  setAccountAddress,
  setChainId,
  selectMetaMaskAccount,
} from "../../slices/metamaskSlice";
import styled from "styled-components";
import { css, keyframes } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import Deposit from "../AvaPoint/Deposit";
import History from "../AvaPoint/History";
import Withdraw from "../AvaPoint/Withdraw";
import ProfileMenu from "../ProfileMenu/ProfileMenu";
import Loading from "../Loading/Loading";
import { FaCheckCircle } from "react-icons/fa";

import Icons from "../../global/icons";

import {
  AlertMySwal,
  ErrorMySwal,
  SuccessMySwal,
  CountdownMySwal,
  showCountdownSwal,
} from "../../global/MySwal";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { removeAuthToken } from "../../services/common";
import { routes } from "../../../components/Router";
import { clearAccount } from "../../slices/accountSlice";
import { clearUser } from "../../slices/loginSlice";
import { changeLanguage, selectSystem } from "../../slices/systemSlice";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  useGetAccountProfileQuery,
  useGetAccountApikeyQuery,
} from "../../services/accountApi";
import { setAccountProfile, addAccountApikey } from "../../slices/accountSlice";

import { setNotification, getNotification } from "../../services/common";
import Web3 from "web3";
import Lang from "../../global/Lang";
import ReferralModal from "../../global/ReferralModal";
import { QRCodeCanvas } from "qrcode.react";

// components
import WalletDialog from "../Wallet/WalletDialog";

import { PiWalletLight } from "react-icons/pi";
import WithdrawalHistory from "../Wallet/WithdrawalHistory";
import DepositHistory from "../Wallet/DepositHistory";

// wallet
import {
  useGetWalletBalanceQuery,
  useCreateWalletMutation,
  useCheckHasWalletQuery,
  useTransferUSDTMutation,
} from "../../services/walletApi";
import {
  useSendEmailAndTelegramMutation,
  useVerifyWithdrawlMutation,
  useVerifyCodeForTransferMutation,
} from "../../services/authApi";

import { FiArrowDownLeft, FiArrowUpRight } from "react-icons/fi";
import { TbTransfer } from "react-icons/tb";
import { SiTether } from "react-icons/si";
import { LiaPiggyBankSolid } from "react-icons/lia";
import { IoWalletOutline } from "react-icons/io5";

// #region styled

const Wrapper = styled.div`
  width: calc(100% - 3.125vw - 3.125vw);
  color: white;
  display: flex;
  border-bottom: 1px solid #3a3d5a;
  align-items: flex-end;
  height: 100%;
  position: relative;

  ${RWD_LG(css`
    border-bottom: 0px;
  `)};
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  ${RWD_LG(css`
    flex-direction: column-reverse;
  `)};
`;

const FinancialWrapper = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  height: 100%;

  ${RWD_LG(css`
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #3a3d5a;
  `)};
`;

const FinancialContainer = styled.div`
  position: absolute;
  width: auto;
  top: 80px;

  overflow: hidden;
  height: ${(props) => (props.open ? "auto" : "0")};
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;

  ${RWD_LG(css`
    top: 55px;
  `)};
`;

const Financial = styled.div`
  padding: 20px;
  background-color: #22254a;
  max-width: 300px;

  ${RWD_SM(css`
    padding: 10px;
  `)};
`;

const BalanceTabWrapper = styled.ul`
  display: flex;
`;

const BalanceTab = styled.li`
  width: 50%;
  text-align: center;
  padding-bottom: 10px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.activeTab ? "2px solid #0aecd1" : "2px solid transparent"};
`;

const BalanceContainer = styled.div`
  font-size: min(max(1.875vw, 34px), 36px);
  font-weight: bold;
  padding: 20px 20px;

  display: flex;
  flex-direction: column;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
`;

const Balance = styled.div``;

const BalanceDefault = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
  /* color: #3a3d5a; */
  font-weight: normal;
`;

const BalanceText = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
  align-self: flex-end;
`;

const FinancialButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding-top: 20px;

  ${RWD_MD(css`
    padding-top: 10px;
  `)};
`;

const FinancialButton = styled.button`
  border-radius: 8px;
  padding: 10px 16px;
  font-size: min(max(0.833vw, 14px), 16px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid rgba(255, 255, 255, 0.15);
  transition: all 0.5s;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  white-space: nowrap;

  :hover {
    border: 1px solid #0aecd1;
  }

  ${RWD_MD(css`
    padding: 10px 20px;
    width: 100%;

    // :last-child {
    //   margin-top: 10px;
    // }
  `)};
`;

const FinancialButtotText = styled.div`
  /* border: 1px solid royalblue; */
  padding-left: 5px;
`;

const AvaPointsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: baseline;
  /* padding-right: 10px; */
  width: auto;

  /* ${RWD_LG(css`
    display: none;
  `)}; */
`;

const AvaPointsContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding-right: 10px;
`;

const AvaPointText = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
  padding-right: 10px;
  white-space: nowrap;
`;

const AvaPoint = styled.div`
  font-weight: bold;
  font-size: min(max(1.145vw, 20px), 22px);
  padding-right: 10px;

  ${RWD_SM(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const AvaPointIcon = styled.div`
  /* width: 1.5vw;
  height: 1.5vw; */
  /* display: inline-flex;
  align-items: center;
  justify-content: center; */
  cursor: pointer;
  transform: ${(props) => (props.open ? "scaleY(-1)" : "scaleY(1)")};
  transition: all 0.3s;

  /* svg {
    width: 1.5vw;
    height: 1.5vw;
  } */
`;

const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & path {
    fill: #0aecd1;
  }

  svg {
    width: 30px;
    height: 30px;
  }
`;

const AccountContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AccountIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AccountIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  position: relative;

  width: 30px;
  height: 30px;

  &:last-child {
    margin-left: 10px;
  }

  svg {
    width: 30px;
    height: 30px;
  }
`;

const Notification = styled.div`
  position: absolute;
  right: 3px;
  top: 1px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  background: #f05d2f;
`;

const AccountText = styled.div`
  font-weight: bold;
  font-size: min(max(1.0416vw, 18px), 20px);
  padding-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 18vw;

  ${RWD_SM(css`
    font-size: min(max(0.9375vw, 16px), 18px);
    display: none;
  `)};
`;

const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  font-size: min(max(1.0416vw, 18px), 20px);
`;

const LanguageText = styled.div`
  padding: 0 10px;
`;

const AvaExchangeWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  font-size: min(max(1.0416vw, 18px), 20px);
  width: 50%;
  align-self: flex-start;
  height: 100%;

  ${RWD_LG(css`
    width: 60%;
    /* position: absolute;
    width: 100%;
    top: 80px;
    height: 45px;
    justify-content: flex-start;
    background-color: #151944; */
  `)};

  ${RWD_MD(css`
    width: 100%;
    /* position: absolute;
    width: 100%;
    top: 80px;
    height: 45px;
    justify-content: flex-start;
    background-color: #151944; */
  `)};
`;

const AvaExchangeIcon = styled.div`
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;

  svg {
    width: 30px;
    height: 30px;
  }

  ${RWD_LG(css`
    width: 25px;
    height: 25px;

    svg {
      width: 25px;
      height: 25px;
    }
  `)};

  ${RWD_SS(css`
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  `)};
`;

const AvaExchangeContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 17.5vw;
  width: 100%;
  flex-wrap: wrap;
  font-size: min(max(0.9375vw, 16px), 18px);
  ${RWD_LG(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};

  /* ${RWD_SM(css`
    width: 50%;
  `)}; */
`;

const AvaExchangeText = styled.span`
  padding: 0 10px;
`;

const AvaExchangeNumber = styled.span`
  margin-left: 0px;

  ${RWD_SM(css`
    margin-left: 0px;
  `)};
`;

const HistoryWrapper = styled.div`
  border-bottom: 1px solid #3a3d5a;
  padding-top: 10px;
  padding-bottom: 5px;

  ${RWD_SM(css`
    padding: 5px 10px;
  `)};
`;

const HistoryContainer = styled.div`
  display: flex;
  padding: 10px 0;
  padding-top: 0;
  cursor: pointer;
  transition: all 0.5s;

  :hover {
    path {
      fill: #0aecd1;
    }
  }
`;

const HistoryIcon = styled.div`
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & path {
    fill: #fcfcfc;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const HistoryText = styled.div`
  padding-left: 10px;
  font-size: min(max(0.833vw, 14px), 16px);
`;

const HamburgerMenuWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  height: 22px;
  width: 30px;
  display: none;
  justify-content: space-between;
  cursor: pointer;
  z-index: 99;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.6s;

  ${RWD_LG(css`
    display: flex;
    position: absolute;
    left: 0;
    top: 15px;
  `)};
`;

const HamburgerMenuItem = styled.span`
  display: inline-block;
  width: 30px;
  background-color: ${(props) => "white"};
  height: 2px;

  transition: all 0.2s ease-in-out;
  transform-origin: center center;
  &:nth-of-type(1) {
    transition-delay: 0.1s;
    width: 15px;
    align-self: flex-start;
    transform-origin: top;

    transform: ${(props) =>
    props.hamIsOpen
      ? "rotate(45deg) translateX(5px) translateY(2px)"
      : "rotate(0deg) translateX(0px) translateY(0px)"};
  }

  :nth-of-type(2) {
    transform-origin: center;

    transform: ${(props) =>
    props.hamIsOpen ? "rotate(-45deg)" : "rotate(0deg)"};
  }

  &:nth-of-type(3) {
    transition-delay: 0.3s;
    width: 15px;
    transform-origin: bottom;
    align-self: flex-end;
    transform: ${(props) =>
    props.hamIsOpen
      ? "rotate(45deg) translateX(-5px) translateY(-1px)"
      : "rotate(0deg) translateX(0px) translateY(0px)"};
  }
`;

const LogoutWrapper = styled.div`
  position: absolute;
  width: auto;
  min-width: 150px;
  top: 80px;
  right: 40px;
  overflow: hidden;
  height: ${(props) => (props.open ? "auto" : "0")};
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  flex-direction: column;
  border: ${(props) =>
    props.open ? "1px solid #0aecd1" : "1px solid transparent"};
  ${RWD_LG(css`
    top: 55px;
    right: 0;
  `)};
`;

const LogoutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  background-color: #22254a;
  cursor: pointer;

  ${RWD_SM(css`
    padding: 10px;
  `)};
`;

const WalletContextWerapper = styled.div`
  margin-right: 30px;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  &:hover {
    border-color: #0aecd1;
    color: #0aecd1;
  }
  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
    position: absolute;
    right: 0px;
    bottom: -100px;
    margin-right: 10px;
    background-color: #22254a;
  `)};
`;

const WalletContextBtn = styled.button``;

const LogoutIcon = styled.div`
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  svg {
    width: 20px;
    height: 20px;
  }

  path {
    fill: #fcfcfc;
  }

  ${LogoutContainer}:hover & {
    path {
      fill: #0aecd1;
    }
  }
`;

const LogoutText = styled.div`
  font-size: min(max(0.833vw, 14px), 15px);
`;

const CoinWrapper = styled.div`
  display: flex;

  ${RWD_SM(css`
    display: none;
  `)};
`;

const CoinContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;

  span {
    color: #3a3d5a;
  }
`;

const floating = keyframes`
   0% {
    transform: translateX(20em);
    opacity: 0.8;
  }
  /* 50% {
    transform: translateY(8%);
  }
  65% {
    transform: translateY(-4%);
  }
  80% {
    transform: translateY(4%);
  }
  95% {
    transform: translateY(-2%);
  } */
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
`;

const NotificationCardWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 120%;
`;

const NotificationCardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NotificationCard = styled.div`
  padding: 15px;
  border: 1px solid #0aecd1;
  transition: 0.5s;
  width: clamp(350px, 25vw, 400px);
  margin-bottom: 10px;
  border-radius: 5px;
  position: relative;
  background: #22254a;
  font-size: 14px;
`;

const NotificationCardIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  & path {
    stroke: #fcfcfc;
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

const NotificationCardContentWrapper = styled.div`
  display: flex;
  padding: 10px 15px 10px 0;
  align-items: flex-start;
`;

const NotificationCardContent = styled.div`
  /* padding: 5px 0; */
  padding-left: 10px;
`;

const NotificationCardLink = styled.div`
  display: flex;
  justify-content: flex-end;

  span {
    color: #0aecd1;
    display: inline-flex;
    cursor: pointer;
    /* padding: 5px 10px; */
  }
`;

const NotificationCardTypeIcon = styled.div`
  display: inline-flex;
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
  }

  & path {
    fill: #fcfcfc;
  }
`;

const NotificationCardTypeIconStroke = styled.div`
  display: inline-flex;
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

// ================ Wallet ================

const DialogTitle = styled.h2`
  color: #151944;
  font-size: min(max(1.354167vw, 24px), 26px);
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

const BalanceAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

const BalanceTotalTItle = styled.span`
  color: #b9b9b9;
  font-size: 14px;
`;

const WalletAmount = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  color: #151944;
  font-weight: 600;
  margin-bottom: 20px;
`;

const BalanceButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 52px;
  margin-top: 20px;
`;

const BalanceButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BalanceButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: #151944;
  border: 1px solid #151944;
  border-radius: 50%;
  cursor: pointer;
  width: 42px;
  height: 42px;
  font-size: 28px;

  &:hover {
    background-color: #151944;
    color: white;
  }
`;

const BalanceButtonText = styled.span`
  font-size: 14px;
  margin-top: 4px;
  color: #151944;
`;

const InputWithMaxButton = styled.div`
  position: relative;
  width: 100%;
`;

const MaxButton = styled.button`
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  padding: 4px 12px;
  color: white;
  background-color: #151944;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: min(max(1vw, 14px), 16px);

  &:hover {
    background-color: #0f123b;
  }

  &:disabled {
    background-color: #b9b9b9;
    color: white;
    cursor: not-allowed;
  }
`;

// 共用input
const WalletInput = styled.input`
  width: 100%;
  color: #151944;
  border: 1px solid #151944;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: min(max(1.25vw, 16px), 18px);
`;

// ================ Deposit & Withdraw ================
const DepositWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  maing: 0 auto;
`;

const QucodeImage = styled.img`
  width: 160px;
  height: 160px;
`;

const QRcodeText = styled.p`
  color: #151944;
`;

const DepositTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 18px auto;
`;

const Depositlable = styled.span`
  color: #151944;
  font-size: min(max(1.25vw, 16px), 18px);
`;

const InputText = styled.p`
  color: #151944;
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
  font-size: 12px;
`;

const WithdrawWrapper = styled(BalanceButton)`
  width: 32%;
  border-radius: 4px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  background-color: #151944;
  color: white;
`;

const AmountSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const WithdrawText = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  color: #9da3ad;
`;

const AmountDisplay = styled.span`
  font-size: 20px;
  color: #151944;
  font-weight: bold;
`;

const NetworkFee = styled.span`
  font-size: 14px;
  color: #9da3ad;
`;

const AmountUnit = styled.span`
  font-size: 14px;
  color: #151944;
`;

const NetworkUnit = styled.span`
  font-size: 14px;
  color: #151944;
  font-weight: bold;
`;

const WithdrawButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const WalletText = styled.span`
  font-size: 14px;
`;

const CopyButton = styled.button`
  margin-left: 8px;
  padding: 6px 12px;
  font-size: 14px;
  color: #fff;
  background-color: #151944;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  padding: 8px;
  margin: 0 8px;
`;

const DepositBtnInput = styled.div`
display: flex;
justify-content: space - between;
align - items: center;
`;

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
  background - color: rgb(30, 34, 64);
  color: #fff;
  font - size: 16px;
  border - radius: 8px;
}

  .Toastify__toast--success {
  background - color: rgba(30, 34, 64, 0.9);
}

  .Toastify__toast--error {
  background - color: #dc3545;
}

  .Toastify__progress - bar {
  background - color: #0aecd1;
}
`;

const ButtonIconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 30px;
    height: 30px;
  }
`;

// ================ Deposit End ================

// ================ Wallet End ================

// #endregion
const Header = ({
  openDepositFun,
  openWithdrawFun,
  openHistoryFun,
  hamIsOpen,
  toggleHamMenu,
  setAlgoInit,
  setMiningWaiting,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const lang = useSelector(selectSystem).language;

  const [language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(lang);
  }, [lang]);

  const btc = useSelector(selectSystem)?.btcusdt;
  const eth = useSelector(selectSystem)?.ethusdt;
  // const bch = useSelector(selectSystem)?.bchusdt;
  const balance = useSelector(selectAccount)?.accountBalance;

  // 取得userprofile
  const { data, isSuccess, isError, error, refetch } =
    useGetAccountProfileQuery();

  // 取得useapikey
  const {
    data: apiKeyData,
    isSuccess: apiKeyIsSuccess,
    isError: apiKeyIsError,
    error: apiKeyError,
    refetch: apiKeyRefetch,
  } = useGetAccountApikeyQuery();

  useEffect(() => {
    // 每次進入頁面都刷新profile
    refetch();
    // 每次進入頁面都刷新apikey
    apiKeyRefetch();
  }, []);
  const [userProfile, setUserProfile] = useState([]);
  const [userApikey, setUserApikey] = useState([]);
  const [notificationIsOpen, setNotificationIsOpen] = useState(false);
  const [referralModalIsOpen, setReferralModalIsOpen] = useState(false);

  // ============================== wallet ==============================
  const [isAvaWalletActive, setIsAvaWalletActive] = useState(false);
  const [isDepositActive, setIsDepositActive] = useState(false);
  const [isWithdrawActive, setIsWithdrawActive] = useState(false);
  const [amount, setAmount] = useState(0);
  const [otpCode, setOtpCode] = useState("");
  const [address, setAddress] = useState("");
  const [hasWallet, setHasWallet] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // 倒數計時 (10 分鐘)
  const [countdown, setCountdown] = useState(0);
  const [isCounting, setIsCounting] = useState(false);

  // 倒數計時 (鎖住按鈕 1 分鐘)
  const [buttonCountdown, setButtonCountdown] = useState(0);
  const [getCodeDisabled, setGetCodeDisabled] = useState(false);

  // wallet 創建後倒數
  const [walletCountdown, setWalletCountdown] = useState(0);
  const [isWalletLoading, setIsWalletLoading] = useState(false);

  // 倒數計時 (10 分鐘)
  useEffect(() => {
    if (isCounting && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    }

    if (countdown === 0) {
      setIsCounting(false);
    }
  }, [countdown, isCounting]);

  // 按鈕倒計時 (1 分鐘)
  useEffect(() => {
    if (getCodeDisabled && buttonCountdown > 0) {
      const timer = setInterval(() => {
        setButtonCountdown((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    }

    if (buttonCountdown === 0) {
      setGetCodeDisabled(false);
    }
  }, [buttonCountdown, getCodeDisabled]);

  // 格式化倒數時間
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  // 用來判斷是否切換帳號
  const account = data?.Profile?.Account;

  // user wallet address copy
  const handleCopy = () => {
    navigator.clipboard.writeText(userWalletAddress);
    toast.success(language === "En" ? "Copied!" : "複製成功", {
      icon: <FaCheckCircle style={{ color: "#0aecd1" }} />,
      position: "bottom-right",
    });
  };

  // wallet api - get wallet balance
  const {
    data: walletData,
    isSuccess: walletIsSuccess,
    isError: walletIsError,
    error: walletError,
    refetch: refetchWallet,
  } = useGetWalletBalanceQuery();

  useEffect(() => {
    if (account) {
      refetchWallet();
    }
  }, [account, refetchWallet]);

  // USDT balance
  const usdtBalance = walletIsSuccess
    ? walletData?.BalanceList?.find((item) => item.CurrencyID === 2)
      ?.AvailableBalance || "0"
    : null;

  // max aount
  const handleMaxClick = () => {
    setAmount(usdtBalance);
  };

  // 允許數字和字母 - OTP
  const handleOTPChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z0-9]*$/.test(value)) {
      setOtpCode(value);
    }
  };

  // 允許數字和字母 - address
  const handleAddressChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z0-9]*$/.test(value)) {
      setAddress(value);
    }
  };

  // clear all input
  const clearAllInputs = () => {
    setAddress("");
    setAmount("");
    setOtpCode("");
  };

  // tg 驗證 - 發送驗證碼 api
  const [
    telegramOTP,
    {
      data: telegramOTPData,
      isLoading: telegramOTPLoading,
      isSuccess: telegramOTPisSuccess,
    },
  ] = useSendEmailAndTelegramMutation();

  // 發送驗證碼 API
  const handleTelegramClick = async () => {
    if (getCodeDisabled) return;

    // 鎖住按鈕，倒數計時一分鐘
    setGetCodeDisabled(true);
    setButtonCountdown(60); // 設置按鈕倒計時為 1 分鐘

    // 啟動驗證碼倒計時
    setCountdown(600); // 設置倒數為 10 分鐘
    setIsCounting(true);

    try {
      const response = await telegramOTP().unwrap();
      console.log(response);
      SuccessMySwal({
        icon: "success",
        title: language === "En" ? "Success" : "發送成功",
        text:
          language === "En"
            ? "Verification code sent successfully. The code is valid for 10 minutes."
            : "驗證碼發送成功。驗證碼有效時間為十分鐘",
        confirmButtonText: language === "En" ? "Confirm" : "確認",
      });
    } catch (err) {
      ErrorMySwal({
        icon: "error",
        title: language === "En" ? "Failed" : "發送失敗",
        text: language === "En" ? "Please try again later." : "請稍後再試",
      });
    } finally {
      setTimeout(() => {
        setGetCodeDisabled(false);
      }, 60000);
    }
  };

  // create wallet
  const [
    createWallet,
    {
      data: createWalletData,
      isLoading: createWalletLoading,
      isSuccess: createWalletIsSuccess,
    },
  ] = useCreateWalletMutation();

  // create wallet function
  // 先檢查 level 是否沒有達到 2
  // 然後再創建錢包，創建如果 Message 是 "Success" 表示成功
  // 成功後會進入倒數（不可跳出），因為要 create wallet
  // 如果失敗，直接 return
  const handleCreateWallet = async () => {
    // 檢查level <2
    if (!isSuccess || !data || data.Profile.Level < 2) {
      ErrorMySwal({
        icon: "error",
        title: language === "En" ? "Failed" : "創建失敗",
        text:
          language === "En"
            ? "You must reach level 2 or higher to create a wallet."
            : "您的等級必須達到 2 或更高才能創建錢包。",
      });
      return;
    }

    try {
      setMenuIsOpen(false);
      const response = await createWallet();

      if (
        response.data?.ResultCode === 0 &&
        response.data?.Message === "Success"
      ) {
        startWalletCountdown();
      } else {
        ErrorMySwal({
          icon: "error",
          title: language === "En" ? "Failed" : "創建失敗",
          text: language === "En" ? "Please try again later." : "請稍後再試",
        });
      }
    } catch (err) {
      ErrorMySwal({
        icon: "error",
        title: language === "En" ? "Failed" : "創建失敗",
        text: language === "En" ? "Please try again later." : "請稍後再試",
      });
    }
  };

  const startWalletCountdown = () => {
    showCountdownSwal({
      title: language === "En" ? "Creating Wallet..." : "創建錢包中...",
      text:
        language === "En"
          ? "Please wait until the wallet creation is complete."
          : "倒數完畢前，請勿進行任何操作",
      countdownSeconds: 60,
    }).then(() => {
      refetchCheckHasWallet();
    });
  };

  // check has wallet API
  const {
    data: checkHasWalletData,
    isSuccess: checkHasWalletIsSuccess,
    refetch: refetchCheckHasWallet,
  } = useCheckHasWalletQuery();

  // 取得address
  const userWalletAddress = checkHasWalletIsSuccess
    ? checkHasWalletData?.Address || ""
    : null;

  // API res: 如果 Message 是 "User already has a wallet." 表示已經有錢包
  useEffect(() => {
    if (checkHasWalletIsSuccess && checkHasWalletData) {
      const walletMessage = checkHasWalletData.Message;
      setHasWallet(walletMessage === "User already has a wallet.");
    }
  }, [checkHasWalletIsSuccess, checkHasWalletData]);

  // withdraw
  // transfer USDT
  const [
    transferUSDT,
    {
      data: transferUSDTResponse,
      isLoading: transferUSDTLoading,
      isSuccess: transferUSDTIsSuccess,
      isError: transferUSDTIsError,
      error: transferUSDTError,
    },
  ] = useTransferUSDTMutation();

  // verify otp
  const [
    verifyWithdraw,
    {
      data: verifyWithdrawResponse,
      isLoading: verifyWithdrawLoading,
      isSuccess: verifyWithdrawIsSuccess,
      isError: verifyWithdrawIsError,
      error: verifyWithdrawError,
    },
  ] = useVerifyCodeForTransferMutation();

  useEffect(() => {
    if (verifyWithdrawIsSuccess) {
      setCountdown(0); // 停止倒數計時
      setIsCounting(false);
      transferUSDT({
        RecipientAddress: address,
        Amount: amount,
        Hash: verifyWithdrawResponse.Hash,
      });
    }

    if (verifyWithdrawIsError) {
      const zhTitle = "OTP 驗證失敗";
      const enTitle = "OTP Verification Failed";
      ErrorMySwal({
        icon: "error",
        title: language === "En" ? enTitle : zhTitle,
        text:
          verifyWithdrawError?.data?.ResultLanguages?.language ||
          verifyWithdrawError?.data?.Message,
      });
    }
  }, [verifyWithdrawResponse, verifyWithdrawIsError]);

  useEffect(() => {
    if (transferUSDTIsSuccess) {
      SuccessMySwal({
        icon: "success",
        title: language === "En" ? "Success" : "提現成功",
        confirmButtonText: language === "En" ? "Confirm" : "確認",
      }).then(() => {
        clearAllInputs();
        setIsWithdrawActive(false);
        refetchWallet();
      });
    }

    if (transferUSDTIsError) {
      ErrorMySwal({
        icon: "error",
        title: language === "En" ? "Failed" : "提現失敗",
        text:
          transferUSDTError?.data?.ResultLanguages?.language ||
          transferUSDTError?.data?.Message,
      });
    }
  }, [transferUSDTResponse, transferUSDTIsError]);

  // withdraw function
  // 先驗證 OTP
  // OTP 過了以後才可以withdraw
  const handleTransferUSDT = async () => {
    // 前置驗證
    if (!amount || Number(amount) <= 0) {
      ErrorMySwal({
        title: language === "En" ? "Error" : "錯誤",
        text:
          language === "En"
            ? "The withdrawal amount must be greater than 0."
            : "提現金額必須大於 0。",
      });
      return;
    }

    if (Number(amount) > Number(usdtBalance)) {
      ErrorMySwal({
        title: language === "En" ? "Error" : "錯誤",
        text:
          language === "En"
            ? "The withdrawal amount cannot exceed your balance."
            : "提現金額不能超過餘額。",
      });
      return;
    }

    if (!address) {
      ErrorMySwal({
        title: language === "En" ? "Error" : "錯誤",
        text:
          language === "En"
            ? "Please enter the recipient's address."
            : "請輸入收款地址。",
      });
      return;
    }

    verifyWithdraw({
      body: { VerifyCode: otpCode },
    });

    // try {
    //   // Step 1: Verify OTP
    //   const verifyResponse = await verifyWithdraw({
    //     body: { VerifyCode: otpCode },
    //   });

    //   const zhTitle = "OTP 驗證失敗";
    //   const enTitle = "OTP Verification Failed";

    //   if (
    //     verifyResponse.error?.data?.ResultCode === 114 ||
    //     verifyResponse.error?.data?.ResultCode === 117
    //   ) {
    //     const zhText =
    //       verifyResponse.error?.data?.ResultLanguages?.Ch || "發生錯誤";
    //     const enText =
    //       verifyResponse.error?.data?.ResultLanguages?.En ||
    //       "An error occurred";

    //     ErrorMySwal({
    //       icon: "error",
    //       title: language === "En" ? enTitle : zhTitle,
    //       text: language === "En" ? enText : zhText,
    //     });

    //     if (verifyResponse.error?.data?.ResultCode === 117) {
    //       setCountdown(0); // 停止倒數計時
    //       setIsCounting(false);
    //     }
    //     return;
    //   } else if (verifyResponse.data?.ResultCode !== 0) {
    //     const zhText = verifyResponse.Message || "請稍後再試";
    //     const enText = verifyResponse.Message || "Please try again later.";
    //     ErrorMySwal({
    //       icon: "error",
    //       title: language === "En" ? enTitle : zhTitle,
    //       text: language === "En" ? enText : zhText,
    //     });
    //     return;
    //   }

    //   // Step 2: Transfer USDT
    //   const transferResponse = await transferUSDT({
    //     RecipientAddress: address,
    //     Amount: amount,
    //     Hash: verifyResponse.data.Hash,
    //   });

    //   if (transferResponse.data.Success) {
    //     SuccessMySwal({
    //       icon: "success",
    //       title: language === "En" ? "Success" : "提現成功",
    //       confirmButtonText: language === "En" ? "Confirm" : "確認",
    //     }).then(() => {
    //       clearAllInputs();
    //       setIsWithdrawActive(false);
    //       refetchWallet();
    //     });
    //   } else {
    //     ErrorMySwal({
    //       icon: "error",
    //       title: language === "En" ? "Failed" : "提現失敗",
    //       text: language === "En" ? "Please try again later." : "請稍後再試",
    //     });
    //     return;
    //   }
    // } catch (err) {
    //   ErrorMySwal({
    //     icon: "error",
    //     title: language === "En" ? "Failed" : "提現失敗",
    //     text: language === "En" ? "Please try again later." : "請稍後再試",
    //   });
    // } finally {
    //   setIsLoading(false);
    // }
  };

  // ============================== wallet End ==============================

  const [notificationData, setNotificationData] = useState([
    {
      id: 1,
      type: "telegram",
      content:
        "Complete Telegram Verification to continue using more AVA services.",
      link: "setting",
      linkText: "Verify Now",
      isOpen: true,
      icon: <Icons.Telegram />,
    },
    {
      id: 2,
      type: "withdrawalVerify",
      content: (
        <Lang
          datakey="notification_withdrawal"
          defaultText="You have not linked a withdrawal address yet. Please bind one to experience more content."
        />
      ),
      link: "setting",
      linkText: <Lang datakey="notification_bind" defaultText="Bind Now" />,
      isOpen: true,
      icon: <Icons.Withdawal />,
    },
    {
      id: 3,
      type: "apikey",
      content: (
        <Lang
          datakey="notification_apikey"
          defaultText="You have not yet linked your Exchange API Key. Please add it to experience Algo Trade services."
        />
      ),
      link: "setting",
      linkText: <Lang datakey="notification_link" defaultText="Link Now" />,
      isOpen: true,
      icon: <Icons.Key />,
    },
    // {
    //   id: 4,
    //   type: "algo",
    //   content: (
    //     <Lang
    //       datakey="notification_algo"
    //       defaultText="You have an inactive Algo Trade. Let's activate it!"
    //     />
    //   ),
    //   link: "algo",
    //   linkText: (
    //     <Lang datakey="notification_activate" defaultText="Activate now" />
    //   ),
    //   isOpen: true,
    //   icon: <Icons.AlgoTrade />,
    // },
    {
      id: 5,
      type: "mining",
      content: "You have an inactive Mining Package. Let's activate it!",
      link: "mining",
      linkText: "Activate now",
      isOpen: true,
      icon: <Icons.Mining />,
    },
  ]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setAccountProfile(data));
      setUserProfile(data?.Profile);
    }

    if (isError) {
      console.log(error?.data?.Message);
    }
  }, [data, isError]);

  useEffect(() => {
    if (apiKeyIsSuccess) {
      dispatch(addAccountApikey(apiKeyData));
      setUserApikey(apiKeyData?.Apikeys);
    }

    if (apiKeyIsError) {
      console.log(apiKeyError?.data?.Message);
    }
  }, [apiKeyData, apiKeyIsError]);

  useEffect(() => {
    setNotificationData(
      notificationData.map((data) => {
        switch (data.type) {
          case "telegram":
            return {
              ...data,
              isOpen:
                getNotification() === "unread"
                  ? !userProfile?.IsTelegramVerified
                  : false,
            };

          case "withdrawalVerify":
            return {
              ...data,
              isOpen:
                getNotification() === "unread"
                  ? !userProfile?.IsWithdrawalVerified
                  : false,
            };

          case "apikey":
            return {
              ...data,
              isOpen:
                getNotification() === "unread" ? !userApikey.length > 0 : false,
            };

          // case "algo":
          //   return {
          //     ...data,
          //     isOpen:
          //       getNotification() === "unread"
          //         ? userProfile?.HasInitAlgo
          //         : false,
          //   };

          case "mining":
            return {
              ...data,
              isOpen:
                getNotification() === "unread"
                  ? userProfile?.HasWaitingMining
                  : false,
            };
        }
      })
    );
    setNotificationIsOpen(true);
  }, [userProfile, userApikey]);

  useEffect(() => {
    if (notificationData.filter((data) => data.isOpen).length == 0) {
      setNotification("read");
      setNotificationIsOpen(false);
    }
  }, [notificationData]);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [logoutMenuIsOpen, setLogoutMenuIsOpen] = useState(false);

  // const [openDeposit, setOpenDeposit] = useState(false);
  // const [openWithdraw, setOpenWithdraw] = useState(false);
  // const [openHistory, setOpenHistory] = useState(false);

  const domRef = useRef(null);

  const logoutRef = useRef(null);

  // 判斷是不是導頁而來
  const { search } = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(search);
    const paramDeposit = query.get("deposit");

    if (paramDeposit === null) return;
    openDepositFun(true);
  }, [search]);

  const clickCallback = (event) => {
    if (domRef.current.contains(event.target)) {
      return;
    }
    if (logoutRef.current.contains(event.target)) {
      return;
    }
    setMenuIsOpen(false);
    setLogoutMenuIsOpen(false);
  };

  useEffect(() => {
    if (menuIsOpen) {
      document.addEventListener("click", clickCallback, false);
      return () => {
        document.removeEventListener("click", clickCallback, false);
      };
    }
  }, [menuIsOpen]);

  useEffect(() => {
    if (logoutMenuIsOpen) {
      document.addEventListener("click", clickCallback, false);
      return () => {
        document.removeEventListener("click", clickCallback, false);
      };
    }
  }, [logoutMenuIsOpen]);

  const handleChangeLanguage = () => {
    dispatch(changeLanguage());
  };

  // logout
  const handleLogout = () => {
    dispatch(clearAccount());
    dispatch(clearUser());
    removeAuthToken();
    navigate(routes.landing);
  };

  const ToggleHamMenu = () => {
    toggleHamMenu(!hamIsOpen);
  };

  const handelLogoutMenu = () => {
    if (menuIsOpen) {
      setMenuIsOpen(false);
    }

    setLogoutMenuIsOpen(!logoutMenuIsOpen);
  };

  const hanedleAVAPointMenu = () => {
    if (logoutMenuIsOpen) {
      setLogoutMenuIsOpen(false);
    }

    setMenuIsOpen(!menuIsOpen);
  };

  const handleNotificationStatus = (e, id) => {
    e.stopPropagation();
    setNotificationData(
      notificationData.map((data) => {
        if (data.id === id) {
          return {
            ...data,
            isOpen: false,
          };
        } else {
          return data;
        }
      })
    );
  };

  const handleNotificationNavigate = (id, link, type) => {
    setNotificationData(
      notificationData.map((data) => {
        if (data.id === id) {
          return {
            ...data,
            isOpen: false,
          };
        } else {
          return data;
        }
      })
    );

    if (type === "algo") {
      // algo table tab 改為 init
      setAlgoInit(true);
      navigate({
        pathname: `${link}`,
      });
    } else if (type === "mining") {
      // mining table tab 改為 waiting
      setMiningWaiting(true);
      navigate({
        pathname: `${link}`,
      });
    } else {
      navigate({
        pathname: link,
        search: `?verified=${type}`,
      });
    }
  };

  const handleCheckNotification = () => {
    setNotificationIsOpen(!notificationIsOpen);
    setNotificationData(
      notificationData.map((data) => {
        switch (data.type) {
          case "telegram":
            return {
              ...data,
              isOpen: !userProfile?.IsTelegramVerified,
            };

          case "withdrawalVerify":
            return {
              ...data,
              isOpen: !userProfile?.IsWithdrawalVerified,
            };

          case "apikey":
            return {
              ...data,
              isOpen: !userApikey.length > 0,
            };

          case "algo":
            return {
              ...data,
              isOpen: userProfile?.HasInitAlgo,
            };

          case "mining":
            return {
              ...data,
              isOpen: userProfile?.HasWaitingMining,
            };
        }
      })
    );
  };

  const ConnectMetaMaskButton = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];
        dispatch(setAccountAddress(account)); // 使用 Redux action 更新帳戶
        // 監聽 chainChanged 事件
        window.ethereum.on("chainChanged", (chainId) => {
          dispatch(setChainId(chainId)); // 使用 Redux action 更新 chainId
        });
        AlertMySwal("連接成功");
      } catch (error) {
        ErrorMySwal({
          title: "Connection Failed",
          text: error.message,
        });
      }
    } else {
      ErrorMySwal({
        title: "Connection Failed",
        text: "You haven't installed MetaMask.",
      });
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleOpenReferral = () => {
    setReferralModalIsOpen(true);
  };

  const userMenuData = [
    {
      key: "profile",
      path: "setting?target=profile",
      nameString: (
        <Lang datakey="setting_subTitle_profile" defaultText="My Profile" />
      ),
      func: handleNavigate,
      icon: <Icons.Profile />,
    },
    {
      key: "withdrawal",
      path: "setting?target=withdrawal",
      nameString: (
        <Lang datakey="deposit_address" defaultText="Deposit Address" />
      ),
      func: handleNavigate,
      icon: <Icons.Withdawal />,
    },
    {
      key: "referral",
      nameString: <Lang datakey="share_referral_code" defaultText="Referral" />,
      func: handleOpenReferral,
      icon: <Icons.Telegram />,
    },
    {
      key: "terms",
      path: "setting?target=terms",
      nameString: <Lang datakey="terms_title" defaultText="Terms Of Service" />,
      func: handleNavigate,
      icon: <Icons.Terms />,
    },
    {
      key: "logout",
      nameString: <Lang datakey="logout" defaultText="Logout" />,
      func: handleLogout,
      icon: <Icons.Logout />,
    },
  ];

  return (
    <Wrapper>
      <Container>
        {createWalletLoading && <Loading />}

        <HamburgerMenuWrapper hamIsOpen={hamIsOpen} onClick={ToggleHamMenu}>
          <HamburgerMenuItem hamIsOpen={hamIsOpen} />
          <HamburgerMenuItem hamIsOpen={hamIsOpen} />
          <HamburgerMenuItem hamIsOpen={hamIsOpen} />
        </HamburgerMenuWrapper>
        <AvaExchangeWrapper>
          <AvaExchangeContainer>
            <AvaExchangeIcon>
              <Icons.BTC />
            </AvaExchangeIcon>
            <AvaExchangeText>BTC</AvaExchangeText>
            <AvaExchangeNumber>$ {btc}</AvaExchangeNumber>
          </AvaExchangeContainer>
          <AvaExchangeContainer>
            <AvaExchangeIcon>
              <Icons.ETH />
            </AvaExchangeIcon>
            <AvaExchangeText>ETH</AvaExchangeText>
            <AvaExchangeNumber>$ {eth}</AvaExchangeNumber>
          </AvaExchangeContainer>
        </AvaExchangeWrapper>

        <FinancialWrapper>
          {/* <WalletContextWerapper>
            <WalletContextBtn onClick={ConnectMetaMaskButton}>
              Connect Wallet
            </WalletContextBtn>
          </WalletContextWerapper> */}
          <CoinWrapper>
            <CoinContainer>
              <AvaPointText>AVA Coins</AvaPointText>
              <AvaPoint>{userProfile?.AvaFreePoints}</AvaPoint>
              <span> | </span>
            </CoinContainer>
          </CoinWrapper>
          <AvaPointsWrapper ref={domRef}>
            {/* onMouseOver={() => setMenuIsOpen(true)} onMouseLeave={() => setMenuActive(false)} */}
            <AvaPointsContainer>
              <AvaPointText>AVA Points</AvaPointText>
              <AvaPoint>
                {Math.floor((userProfile?.AvaPoints ?? 0) * 100) / 100}
              </AvaPoint>
              <AvaPointIcon open={menuIsOpen} onClick={hanedleAVAPointMenu}>
                <Icons.Arrow />
              </AvaPointIcon>
            </AvaPointsContainer>

            {hasWallet ? (
              <ButtonContainer onClick={() => setIsAvaWalletActive(true)}>
                <ButtonIconContainer>
                  <IoWalletOutline />
                </ButtonIconContainer>
                {/* <ButtonContainerText>
                  AVA Wallet
                </ButtonContainerText> */}
              </ButtonContainer>
            ) : (
              <ButtonContainer onClick={handleCreateWallet}>
                <ButtonIconContainer>
                  <IoWalletOutline />
                </ButtonIconContainer>
                {/* <ButtonContainerText>
                  Create Wallet
                </ButtonContainerText> */}
              </ButtonContainer>
            )}

            <FinancialContainer open={menuIsOpen}>
              {/* onMouseOver={() => setMenuActive(true)}
              onMouseLeave={() => setMenuIsOpen(false)} */}
              <Financial>
                {/* <BalanceTabWrapper>
                  <BalanceTab
                    activeTab={activeTab === 0}
                    onClick={() => setActiveTab(0)}
                  >
                    BINANCE
                  </BalanceTab>
                  <BalanceTab
                    activeTab={activeTab === 1}
                    onClick={() => setActiveTab(1)}
                  >
                    BYBIT
                  </BalanceTab>
                </BalanceTabWrapper>
                <BalanceContainer>
                  {balance[activeTab]?.amount ? (
                    <Balance>{balance[activeTab]?.amount}</Balance>
                  ) : (
                    <BalanceDefault>尚未設定API Key</BalanceDefault>
                  )}

                  <BalanceText>USDT</BalanceText>
                </BalanceContainer> */}

                <HistoryWrapper onClick={() => openHistoryFun(true)}>
                  <HistoryContainer>
                    <HistoryIcon>
                      <Icons.History />
                    </HistoryIcon>
                    <HistoryText>
                      <Lang
                        datakey="transaction_history"
                        defaultText="Transaction History"
                      />
                    </HistoryText>
                  </HistoryContainer>
                </HistoryWrapper>

                {/* Deposit & Withdraw */}
                <FinancialButtonWrapper>
                  <FinancialButton onClick={() => openDepositFun(true)}>
                    <IconContainer>
                      <Icons.Deposit />
                    </IconContainer>
                    <FinancialButtotText>
                      {/* <Lang datakey="deposit" defaultText="Deposit AVA Point" /> */}
                      Deposit AVA Point
                    </FinancialButtotText>
                  </FinancialButton>
                  <FinancialButton onClick={() => openWithdrawFun(true)}>
                    <IconContainer>
                      <Icons.Withdraw />
                    </IconContainer>
                    <FinancialButtotText>
                      {/* <Lang datakey="withdraw" defaultText="Withdraw AVA Point" /> */}
                      Withdraw AVA Point
                    </FinancialButtotText>
                  </FinancialButton>
                </FinancialButtonWrapper>
              </Financial>
            </FinancialContainer>
          </AvaPointsWrapper>

          <AccountContainer ref={logoutRef}>
            <AccountText>
              {userProfile?.Name || userProfile?.Account?.split("@")[0]}
            </AccountText>
            <AccountIconWrapper>
              <AccountIcon onClick={handelLogoutMenu}>
                <Icons.User />
              </AccountIcon>

              <AccountIcon onClick={handleCheckNotification}>
                <Icons.Bell />
                {notificationData.filter((data) => data.isOpen).length > 0 &&
                  getNotification() === "unread" && (
                    <Notification></Notification>
                  )}
              </AccountIcon>
            </AccountIconWrapper>

            <LogoutWrapper open={logoutMenuIsOpen}>
              {userMenuData.map((data) => (
                <LogoutContainer
                  onClick={() => data.func(data.path)}
                  key={data.key}
                >
                  <LogoutIcon>{data.icon}</LogoutIcon>
                  <LogoutText>{data.nameString}</LogoutText>
                </LogoutContainer>
              ))}
            </LogoutWrapper>
          </AccountContainer>

          <LanguageContainer onClick={handleChangeLanguage}>
            <LanguageText>
              {lang.toLowerCase() === "en" ? "EN" : "中"}
            </LanguageText>
          </LanguageContainer>
        </FinancialWrapper>
      </Container>
      {/* {openDeposit && <Deposit setOpen={setOpenDeposit} />}
      {openWithdraw && <Withdraw setOpen={setOpenWithdraw} />}
      {openHistory && <History setOpen={setOpenHistory} />} */}

      {notificationIsOpen && (
        <NotificationCardWrapper>
          <NotificationCardContainer>
            {notificationData
              .filter((item) => item.isOpen)
              .map((data) => (
                <NotificationCard
                  key={data.id}
                  num={data.id}
                  onClick={() =>
                    handleNotificationNavigate(data.id, data.link, data.type)
                  }
                >
                  <NotificationCardIcon
                    onClick={(e) => handleNotificationStatus(e, data.id)}
                  >
                    <Icons.Close />
                  </NotificationCardIcon>

                  <NotificationCardContentWrapper>
                    {data.type === "algo" ? (
                      <NotificationCardTypeIconStroke>
                        {data.icon}
                      </NotificationCardTypeIconStroke>
                    ) : (
                      <NotificationCardTypeIcon>
                        {data.icon}
                      </NotificationCardTypeIcon>
                    )}

                    <NotificationCardContent>
                      {data.content}
                    </NotificationCardContent>
                  </NotificationCardContentWrapper>

                  <NotificationCardLink>
                    <span>{data.linkText}</span>
                  </NotificationCardLink>
                </NotificationCard>
              ))}
          </NotificationCardContainer>
        </NotificationCardWrapper>
      )}

      {referralModalIsOpen && (
        <ReferralModal
          code={userProfile?.ReferenceCode}
          setReferralModalIsOpen={setReferralModalIsOpen}
        />
      )}

      <WalletDialog
        isOpen={isAvaWalletActive}
        onClose={() => setIsAvaWalletActive(false)}
      >
        <DialogTitle>AVA Wallet</DialogTitle>
        <BalanceAmountWrapper>
          <BalanceTotalTItle>Your Balance</BalanceTotalTItle>

          <WalletAmount>
            <SiTether />
            <WalletText>USDT</WalletText>$ {usdtBalance}
          </WalletAmount>
        </BalanceAmountWrapper>

        <BalanceButtonWrapper>
          <BalanceButtonContainer>
            <BalanceButton
              onClick={() => {
                setIsDepositActive(true);
                setIsAvaWalletActive(false);
              }}
            >
              <FiArrowDownLeft />
            </BalanceButton>
            <BalanceButtonText>Deposit</BalanceButtonText>
          </BalanceButtonContainer>

          <BalanceButtonContainer>
            <BalanceButton
              onClick={() => {
                setIsWithdrawActive(true);
                setIsAvaWalletActive(false);
              }}
            >
              <FiArrowUpRight />
            </BalanceButton>
            <BalanceButtonText>Withdraw</BalanceButtonText>
          </BalanceButtonContainer>
        </BalanceButtonWrapper>
      </WalletDialog>

      {/* deposit dialog */}
      <WalletDialog
        isOpen={isDepositActive}
        onClose={() => setIsDepositActive(false)}
      >
        <DialogTitle>DEPOSIT USDT</DialogTitle>

        <DepositWrapper>
          {walletIsSuccess && userWalletAddress?.toString().trim() ? (
            <QRCodeCanvas
              value={userWalletAddress.toString().trim()}
              size={140}
              bgColor={"transparent"}
            />
          ) : (
            <QRcodeText>Loading QR Code...</QRcodeText>
          )}
        </DepositWrapper>

        <DepositTextWrapper>
          {/* Network & Deposit Address */}
          <Depositlable>NetWork(Only support BEP-20)</Depositlable>
          <WalletInput type="text" value={"BEP-20"} readOnly />
        </DepositTextWrapper>

        <DepositTextWrapper>
          <Depositlable>Deposit Address</Depositlable>
          <DepositBtnInput>
            <WalletInput type="text" value={userWalletAddress} readOnly />
            <CopyButton onClick={handleCopy}>Copy</CopyButton>
          </DepositBtnInput>
          <DepositHistory />
        </DepositTextWrapper>
      </WalletDialog>

      {/* withdraw dialog */}
      <WalletDialog
        isOpen={isWithdrawActive}
        onClose={() => {
          setIsWithdrawActive(false);
          clearAllInputs();
        }}
      >
        {(telegramOTPLoading ||
          verifyWithdrawLoading ||
          transferUSDTLoading) && <Loading />}

        <DialogTitle>Withdraw USDT</DialogTitle>

        <DepositTextWrapper>
          <Depositlable>Select Network (Only support BEP-20)</Depositlable>
          <WalletInput type="text" value={"BEP-20"} readOnly />
        </DepositTextWrapper>

        <DepositTextWrapper>
          <Depositlable>Address</Depositlable>
          <WalletInput
            type="text"
            value={address}
            placeholder="Enter address"
            onChange={handleAddressChange}
          />
        </DepositTextWrapper>

        <DepositTextWrapper style={{ marginBottom: "4px" }}>
          <Depositlable>Withdraw amount</Depositlable>
          <InputWithMaxButton>
            <WalletInput
              value={amount}
              onChange={(e) =>
                setAmount(
                  e.target.value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, "$1")
                )
              }
              placeholder="Enter amount"
            />
            <MaxButton onClick={handleMaxClick} style={{ top: "31.5%" }}>
              Max
            </MaxButton>
            <InputText>
              {walletIsSuccess ? `Balance: ${usdtBalance} USDT` : "Loading..."}
            </InputText>
          </InputWithMaxButton>
        </DepositTextWrapper>

        <DepositTextWrapper style={{ marginTop: "4px" }}>
          <Depositlable>Telegram OTP</Depositlable>
          <InputWithMaxButton>
            <WalletInput
              type="text"
              value={otpCode}
              onChange={handleOTPChange}
              placeholder="Enter OTP"
            />
            <MaxButton
              onClick={handleTelegramClick}
              disabled={getCodeDisabled}
              style={{
                top: isCounting ? "31.5%" : undefined,
              }}
            >
              {getCodeDisabled ? `${buttonCountdown}s` : "Get code"}
            </MaxButton>
            {isCounting && (
              <InputText>
                {language === "En"
                  ? "Verification code valid time: "
                  : "驗證碼有效時間："}
                {formatTime(countdown)}
              </InputText>
            )}
          </InputWithMaxButton>
        </DepositTextWrapper>

        <WithdrawButtonBox>
          <AmountSection>
            <WithdrawText>
              {language === "En" ? "Receive amount" : "收款金額"}
              <TbTransfer />
            </WithdrawText>

            <AmountDisplay>
              <AmountDisplay>
                {amount > 0 ? (amount - 1).toFixed(1) : "0.0"}
              </AmountDisplay>
              <AmountUnit> USDT</AmountUnit>
            </AmountDisplay>

            <NetworkFee>
              {/* 網路費用: */}
              {language === "En" ? "Network fee" : "網路費用"}
              <NetworkUnit> 1 USDT</NetworkUnit>
            </NetworkFee>
          </AmountSection>

          <WithdrawWrapper onClick={handleTransferUSDT}>
            Withdraw
          </WithdrawWrapper>
        </WithdrawButtonBox>
        <WithdrawalHistory />
      </WalletDialog>

      {/* <StyledToastContainer
        position="bottom-right"
        autoClose={1000}
        hideProgressBar={true}
      /> */}
    </Wrapper>
  );
};

export default Header;
