import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import Icons from "../../global/icons";

import BCH from "../../images/crypto/bch.png";
import BNB from "../../images/crypto/bnb.png";
import BTC from "../../images/crypto/btc.png";
import CAKE from "../../images/crypto/cake.png";
import ETH from "../../images/crypto/eth.png";
import IOTA from "../../images/crypto/iota.png";
import NEO from "../../images/crypto/neo.png";
import USDT from "../../images/crypto/usdt.png";
import EOS from "../../images/crypto/eos.png";
import ETC from "../../images/crypto/etc.png";

import Lang from "../../global/Lang";

import { Fragment } from "react";

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  display: none;

  ${RWD_MD(css`
    display: flex;
    width: 100%;
    background-color: #22254a;
    margin-bottom: 10px;
    border-radius: 8px;
  `)};

  ${RWD_SM(css`
    padding: 10px;
  `)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #fcfcfc;
`;

const Date = styled.div`
  padding: 5px 0;
  font-size: 14px;
  color: #54587b;
`;

const Content = styled.div`
  padding-left: 10px;
`;

const ContetWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  align-items: center;
`;

const Name = styled.div`
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ContentContainer = styled.div`
  display: flex;

  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid #3a3d5a;
`;

const VolumeWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0;
  justify-content: space-between;
`;

const VolumeTitle = styled.div`
  font-size: min(max(0.9375vw, 12px), 18px);
  color: rgba(255, 255, 255, 0.2);
`;

const VolumeText = styled.div``;

const VolumeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const VolumeSingleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const VolumeCombineWrapper = styled.div`
  display: flex;
`;

const Volume = styled.div`
  padding-left: 10px;
`;

const MiningTextWrapper = styled.div`
  padding-left: 10px;
  color: ${(props) => (props.type === "true" ? "#1CAC2A" : "#F05D2F")};
`;

const ReturnTypeButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.status ? "10px 0" : " 10px 0 0 0")};
  border-top: 1px solid #3a3d5a;
  cursor: pointer;

  svg {
    transition: all 0.3s;
    transform: ${(props) => (props.status ? "scaleY(-1)" : "scaleY(1)")};
  }
`;

const ReturnTypeButton = styled.div`
  padding: 8px 0px;
  width: 100%;
  border-radius: 8px;
  text-align: center;
  background-color: #4643ca;
  color: #fcfcfc;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const ReportTXIDButton = styled.div`
  padding: 8px 0px;
  width: 100%;
  border-radius: 8px;
  text-align: center;
  background-color: #e89924;
  color: #fcfcfc;
  cursor: pointer;
`;

const CancelWaitingPackageButton = styled.div`
  border: 1px solid #54587b;
  padding: 8px 0px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  color: #54587b;
`;

const IconWrapper = styled.div`
  display: inline-flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  :hover {
    & path {
      fill: #0aecd1;
    }
  }

  & path {
    fill: #fcfcfc;
  }
`;

const TargetWrapper = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
`;

const DetailButtonWrapper = styled.div`
  font-size: 12px;

  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  /* margin-left: auto; */
  padding-bottom: 10px;
  color: #fcfcfc;
  cursor: pointer;
`;

const ReturnTypeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ReturnTypeContainer = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
`;

const MiningTableRwd = ({
  data,
  language,
  transferDeposit,
  transferMining,
  handleEditName,
  handleChangeReturnType,
  handleTxid,
  handleCancelWaitingPackage,
  handleDataOpen,
  handleButtonShow,
  setHistoryModalIsOpen,
  transferUsdt,
  handlePreviewTransferPackage,
  handleSelectTransferPackage,
  WPbuttonHandler,
}) => {
  // console.log(data);
  const cryptologos = [
    { Src: BCH, Target: "BCH" },
    { Src: BNB, Target: "BNB" },
    { Src: BTC, Target: "BTC" },
    { Src: CAKE, Target: "CAKE" },
    { Src: ETH, Target: "ETH" },
    { Src: IOTA, Target: "IOTA" },
    { Src: NEO, Target: "NEO" },
    { Src: USDT, Target: "USDT" },
    { Src: EOS, Target: "EOS" },
    { Src: ETC, Target: "ETC" },
  ];

  const handleFetchDetailData = (id) => {
    handleDataOpen(id);
    setHistoryModalIsOpen(true);
  };



  return (
    <Wrapper>
      <Container>
        <ContetWrapper>
          <NameWrapper>
            <TargetWrapper>
              <img
                src={
                  cryptologos.filter((logo) => logo.Target === data.Target)[0]
                    ?.Src
                }
              />
            </TargetWrapper>
            <Name>
              {data.Name} ({data.PackageName})
            </Name>
            <IconWrapper onClick={() => handleEditName(data.GroupId)}>
              <Icons.Edit />
            </IconWrapper>
          </NameWrapper>

          <VolumeWrapper>
            <VolumeSingleWrapper>
              <VolumeTitle>
                <Lang
                  datakey="mining_table_currentdeposit"
                  defaultText="CurrentVolume/Deposit"
                />
              </VolumeTitle>
              <VolumeText>
                {data.Status === "over" ? (
                  `NaN / ${data.Deposit}`
                ) : transferDeposit(data.PeriodItems) == 0 ? (
                  <Lang
                    datakey="mining_getPrice"
                    defaultText="Getting market price"
                  />
                ) : (
                  `${transferDeposit(data.PeriodItems).toFixed(2)} / ${data.Deposit
                  }`
                )}
              </VolumeText>
            </VolumeSingleWrapper>

            <VolumeCombineWrapper>
              <VolumeContainer>
                <VolumeTitle>
                  <Lang
                    datakey="mining_table_withdrawalvolume"
                    defaultText="WithdrawalVolume"
                  />
                </VolumeTitle>
                <VolumeText>
                  <Volume>
                    {transferUsdt(data.Target, data.WithdrawalVolume).toFixed(
                      2
                    )}
                  </Volume>
                </VolumeText>
              </VolumeContainer>
              <VolumeContainer>
                <VolumeTitle>
                  <Lang datakey="mining_table_mining" defaultText="Mining" />
                </VolumeTitle>
                <MiningTextWrapper
                  type={transferMining(data.PeriodItems) > 0 ? "true" : "false"}
                >
                  ({transferMining(data.PeriodItems).toFixed(2)})
                </MiningTextWrapper>
              </VolumeContainer>
            </VolumeCombineWrapper>
          </VolumeWrapper>
        </ContetWrapper>
        <ReturnTypeWrapper>
          <Date>{data.PeriodEndDate}</Date>
          <ReturnTypeContainer>
            <Content>
              {(() => {
                switch (data.ReturnType) {
                  case "no":
                    return "CP";
                  case "all":
                    return "WP";
                  case "interest":
                    return "WMT";
                  case "avapoint":
                    return "MAP";
                }
              })()}
            </Content>
            <Content>
              {data.SubDays
                ? data.SubDays.toLowerCase() === "over"
                  ? `(Locked)`
                  : data.SubDays == 0
                    ? `(1 day left)`
                    : `(${data.SubDays} days left)`
                : "-"}
            </Content>
          </ReturnTypeContainer>
        </ReturnTypeWrapper>
      </Container>
      <DetailButtonWrapper onClick={() => handleFetchDetailData(data.GroupId)}>
        <Lang datakey="detail" defaultText="detail" />
      </DetailButtonWrapper>

      {data.Status === "running" && data.SubDays.toLowerCase() !== "over" && (
        <Fragment>
          <ReturnTypeButtonWrapper
            status={data.buttonIsShow}
            onClick={() => handleButtonShow(data.GroupId)}
          >
            <Icons.Arrow />
          </ReturnTypeButtonWrapper>
          {data.buttonIsShow && (
            <Fragment>
              {data.Status === "running" && (
                <Fragment>
                  {data.IsWaitingTransfer ? (
                    <ReturnTypeButton
                      onClick={() => handlePreviewTransferPackage(data.GroupId)}
                    >
                      Preview
                    </ReturnTypeButton>
                  ) : (
                    <Fragment>
                      <ReturnTypeButton
                        onClick={() => handleSelectTransferPackage(data.GroupId)}
                      >
                        Transfer
                      </ReturnTypeButton>

                      {[38, 39, 40, 41, 42, 43].includes(data.PackageId) && (
                        <ReturnTypeButton
                          onClick={() => WPbuttonHandler(data.GroupId)}
                        >
                          {language === "En" ? "Dynamic Close" : "動態結算"}
                        </ReturnTypeButton>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
              <ReturnTypeButton
                onClick={() => handleChangeReturnType(data.GroupId)}
              >
                Change Return Type
              </ReturnTypeButton>
            </Fragment>
          )}
        </Fragment>
      )}

      {/* {data.buttonIsShow && (
        <Fragment>
          {data.Status === "running" &&
            data.SubDays.toLowerCase() !== "over" && (
              <ReturnTypeButton
                onClick={() => handleChangeReturnType(data.GroupId)}
              >
                Change Return Type
              </ReturnTypeButton>
            )}
        </Fragment>
      )} */}

      {data.Status === "waiting" && (
        <ButtonWrapper>
          <ReportTXIDButton onClick={() => handleTxid(data.GroupId)}>
            Report TXID
          </ReportTXIDButton>
          <CancelWaitingPackageButton
            onClick={() => handleCancelWaitingPackage(data.GroupId)}
          >
            Cancel
          </CancelWaitingPackageButton>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

export default MiningTableRwd;
