import styled, { keyframes, css } from "styled-components";
import { RWD_LG, RWD_LL, RWD_MD, RWD_SM } from "../../utils/rwd";
import { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Lang from "../../global/Lang";
import { changeLanguage, selectSystem } from "../../slices/systemSlice";
import LogoImg from "../../../img/ava_logo_dark.png";

import Robot from "../../images/robot.png";

import Email from "./components/Email";
import VerifyEmail from "./components/VerifyEmail";
import Password from "./components/Password";
import DirectionModal from "./components/DirectionModal";
import PhoneAndReferralCode from "./components/PhoneAndReferralCode";
import VerifyTelegram from "./components/VerifyTelegram";
import { motion } from "framer-motion";

import { routes } from "../../../components/Router";
import { handleParseLanguage } from "../../utils";
import { useGetVerifyAccountMutation } from "../../services/authApi";
import { ErrorMySwal } from "../../global/MySwal";
import { getLanguage } from "../../services/common";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100svh;
  background-color: #151944;
  font-family: Poppins;
  position: relative;
`;

const Header = styled.header`
  height: 6.25vw;
  display: flex;
  align-items: center;
  justify-content: center;

  ${RWD_MD(css`
    height: 8vh;
  `)};
  ${RWD_SM(css`
    height: 65px;
  `)};
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 81.88vw;

  ${RWD_MD(css`
    width: 83.33vw;
  `)};
`;

const LogoContainer = styled.div`
  width: 5.729vw;
  display: flex;
  cursor: pointer;

  ${RWD_MD(css`
    width: 11vw;
  `)};

  ${RWD_SM(css`
    width: 15.33vw;
  `)};
`;

const LanguageWrapper = styled.div`
  display: flex;
  align-items: end;
  color: #fcfcfc;
`;

const LanguageText = styled.div`
  cursor: pointer;
`;

const Title = styled.h1`
  color: #fcfcfc;
  font-size: clamp(24px, 2.0833vw, 40px);
`;

const Container = styled.div`
  color: white;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2vh;
  width: clamp(500px, 61.88vw, 650px);

  ${RWD_LL(css`
    height: 80vh;
    width: 81.88vw;
  `)};

  ${RWD_MD(css`
    height: 75vh;
    padding-top: 50px;
    width: 83.33vw;
  `)};

  ${RWD_SM(css`
    height: 70svh;
    padding-top: 20px;
  `)};
`;

const RobotWrapper = styled.div`
  display: flex;
  position: fixed;
  right: 3vw;
  bottom: 50px;

  ${RWD_SM(css`
    bottom: 4svh;
    right: 8.335vw;
  `)};
`;

const RobotImg = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* position: absolute; */
  width: clamp(60px, 7vw, 100px);
  height: clamp(60px, 7vw, 100px);
  background: white;
  /* background: rgba(255, 255, 255, 0.15); */
  border-radius: 50%;
  padding: 10px;
  border: 1px solid #fcfcfc;
  overflow: hidden;
  border: 3px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.5), white),
    linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%);
  cursor: pointer;
  overflow: hidden;

  img {
    position: absolute;
    top: 20%;
  }

  ${RWD_SM(css`
    border-width: 2px;
  `)};
`;

const RobotContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 15px;

  ${RWD_SM(css`
    gap: 10px;
  `)};
`;

const ProgressList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 50px;
`;

const ProgressItem = styled.li`
  width: ${(props) => (props.isActive ? "15px" : " 6px")};
  height: 6px;
  border-radius: ${(props) => (props.isActive ? "10px" : " 50%")};
  background: ${(props) =>
    props.isActive ? "#2f80ed" : "rgba(255, 255, 255, 0.15)"};
  transition: all 0.3s;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  button:last-child {
    margin-left: auto;
  }
`;

const Button = styled.button`
  min-width: 120px;
  border-radius: 60px;
  color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
  background: ${(props) =>
    props.type === "confirm"
      ? props.disabled
        ? "#3A3D5A"
        : "linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%)"
      : ""};
  border-color: ${(props) =>
    props.type === "default" ? "#fcfcfc" : props.disabled ? "#d9d9d9" : ""};
  display: flex;
  justify-content: center;
  border-width: ${(props) => (props.type === "default" ? "1px" : "")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

const ButtonText = styled.span`
  padding: 10px 16px;
  text-align: center;
  /* font-size: min(max(1.0416vw, 14px), 20px); */
  color: #fcfcfc;
`;

const blinkCaret = keyframes`
 	50% { border-right-color: transparent; }
`;

const ChatBubble = styled(motion.div)`
  color: #151944;
  padding: 10px 20px;
  border-radius: 15px;
  background: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2.5px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to top, white, white),
    linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%);
  font-family: "Poppins", "Noto Sans TC", sans-serif;
  font-size: clamp(13px, 1.041667vw, 20px);

  ${RWD_SM(css`
    margin-right: 0px;
    padding: 5px 10px;
  `)};
`;

const Chat = styled(motion.span)``;

const NewRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = useSelector(selectSystem).language;
  const stepCount = 5;
  const { emailVerifyCode } = useParams();

  const [time, setTime] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isShowRobot, setIsShowRobot] = useState(false);
  const [step, setStep] = useState(1);
  const [randomHelpText, setRandomHelpText] = useState(null);

  // 參數
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  // const [referenceCode, setReferenceCode] = useState("");

  const [_getVerifyAccount, { data, isSuccess, isError, isLoading, error }] =
    useGetVerifyAccountMutation();

  const handleGetVerifyAccount = async () => {
    _getVerifyAccount({
      body: {
        VerifyCode: emailVerifyCode,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setAccount(data?.Account);
      setStep(2);
    }

    if (isError) {
      ErrorMySwal({
        title:
          error?.data?.ResultLanguages?.[getLanguage()] || error?.data?.Message,
      });
    }
  }, [data, isError, isLoading]);

  // 推薦碼導頁
  // const { search } = useLocation();
  // useEffect(() => {
  //   const query = new URLSearchParams(search);
  //   setReferenceCode(query.get("invites"));
  // }, [search]);

  // 點擊驗證信連結
  useEffect(() => {
    if (emailVerifyCode) {
      handleGetVerifyAccount();
    }
  }, [emailVerifyCode]);

  const reset = () => {
    if (time) {
      clearTimeout(time);
    }
    const newTime = setTimeout(() => {
      setIsShowRobot(true);
    }, 5000);
    setTime(newTime);
  };

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * helpText.length);
    setRandomHelpText(randomIndex);
  }, []);

  useEffect(() => {
    const handleUserActivity = () => {
      reset();
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("touchstart", handleUserActivity);
    window.addEventListener("touchmove", handleUserActivity);

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("touchstart", handleUserActivity);
      window.removeEventListener("touchmove", handleUserActivity);

      if (time) {
        clearTimeout(time);
      }
    };
  }, [time]);

  useEffect(() => {
    reset();
    setIsShowRobot(false);
  }, [step]);

  const handleShowRobot = () => {
    setIsShow(true);
  };

  const helpText = [
    {
      id: 1,

      text: {
        ch: "您好！請問需要幫助嗎？",
        en: "Hello! Do you need any help?",
      },
    },
    {
      id: 2,
      text: {
        ch: "您好！有什麼我可以幫忙的嗎？",
        en: "Hello! Is there anything I can help you with?",
      },
    },
    {
      id: 3,
      text: {
        ch: "您好！需要任何協助嗎？",
        en: "Hello! Do you need any assistance?",
      },
    },
  ];

  const sentenceVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        staggerChildren: 0.1,
        delay: 0.5,
        duration: 0.5,
      },
    },
  };

  const letterVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { opacity: { duration: 0 } },
    },
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handleBackToHome = () => {
    navigate(routes.landing);
  };

  const handleChangeLanguage = () => {
    dispatch(changeLanguage());
  };

  return (
    <Wrapper>
      <Header>
        <LogoWrapper>
          <LogoContainer onClick={handleBackToHome}>
            <img src={LogoImg} alt="ava_logo" />
          </LogoContainer>
          <LanguageWrapper onClick={handleChangeLanguage}>
            <LanguageText>
              {lang?.toLowerCase() === "en" ? "EN" : "中"}
            </LanguageText>
          </LanguageWrapper>
        </LogoWrapper>
      </Header>

      <Title>{handleParseLanguage("register")}</Title>

      <Container>
        {(() => {
          switch (step) {
            case 1:
              return (
                <Email
                  account={account}
                  handleNextStep={handleNextStep}
                  setAccount={setAccount}
                  stepCount={stepCount}
                  step={step}
                />
              );
            case 2:
              return (
                <VerifyEmail
                  account={account}
                  handleNextStep={handleNextStep}
                  stepCount={stepCount}
                  step={step}
                />
              );
            case 3:
              return (
                <Password
                  account={account}
                  handleNextStep={handleNextStep}
                  stepCount={stepCount}
                  step={step}
                  password={password}
                  setPassword={setPassword}
                />
              );
            case 4:
              return (
                <PhoneAndReferralCode
                  account={account}
                  password={password}
                  step={step}
                  stepCount={stepCount}
                  handleNextStep={handleNextStep}
                />
              );
            case 5:
              return (
                <VerifyTelegram
                  step={step}
                  stepCount={stepCount}
                  setStep={setStep}
                  account={account}
                />
              );
          }
        })()}
      </Container>
      {isShowRobot && (
        <RobotWrapper>
          <RobotContainer>
            <ChatBubble
              variants={sentenceVariants}
              initial="hidden"
              animate="visible"
            >
              {helpText[randomHelpText]?.text?.[lang?.toLowerCase()]
                ?.split("")
                .map((char, i) => (
                  <Chat key={`${char}-${i}`} variants={letterVariants}>
                    {char === " " ? "\u00A0" : char}
                  </Chat>
                ))}
            </ChatBubble>
            <RobotImg
              onClick={handleShowRobot}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.5,
                delay: 0.3,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              <motion.img src={Robot} alt="robot" whileHover={{ scale: 1.1 }} />
            </RobotImg>
          </RobotContainer>
        </RobotWrapper>
      )}

      {isShow && <DirectionModal setIsShow={setIsShow} step={step} />}
    </Wrapper>
  );
};

export default NewRegister;
