import styled, { css } from "styled-components";
import { useEffect, useState } from "react";
import { RWD_SM } from "../../../utils/rwd";

import { useSendEmailMutation } from "../../../services/authApi";
import { EmailFormat } from "../../../utils";
import Progress from "./Progress";
import { handleParseLanguage } from "../../../utils";
import { SuccessMySwal } from "../../../global/MySwal";
import { getLanguage } from "../../../services/common";

import Loading from "../../../components/Loading/Loading";

const Wrapper = styled.div`
  display: flex;
`;

const Container = styled.div`
  width: clamp(280px, 40vw, 500px);
  flex: 1;
  display: flex;
  flex-direction: column;

  ${RWD_SM(css`
    width: 85vw;
  `)};
`;

const Title = styled.h2`
  font-size: clamp(22px, 1.822vw, 35px);
  font-weight: 600;
`;

const Info = styled.div`
  margin-bottom: 20px;
  font-size: clamp(14px, 0.833vw, 16px);
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputEl = styled.input`
  color: #fcfcfc;

  padding: 10px 10px;
  width: 100%;

  color: #fcfcfc;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */

  /* background: rgba(255, 255, 255, 0.1); */
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );

  &::placeholder {
    font-size: clamp(14px, 0.833vw, 16px);
  }
`;

const LabelEl = styled.label`
  /* font-size: 20px; */
`;

const FormEl = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  justify-content: space-between;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorMsg = styled.div`
  font-size: clamp(14px, 0.833vw, 15px);
  color: #f05d2f;
  height: 20px;
`;

const Email = ({ account, setAccount, stepCount, step, handleNextStep }) => {
  const [errorMsg, setErrorMsg] = useState("");
  const isValid = account && EmailFormat(account);
  const [_sendEmail, { isSuccess, isError, error, isLoading }] =
    useSendEmailMutation();

  const handleSendMail = async (e) => {
    e.preventDefault();
    await _sendEmail({
      body: {
        Account: account,
        ReferenceCode: "",
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      // 多國語言
      SuccessMySwal({
        title: handleParseLanguage("register_verification_notice"),
        text: handleParseLanguage("verify_code_sent"),
        showCancelButton: false,
        confirmButtonText: handleParseLanguage("confirm"),
      }).then((result) => {
        if (result.isConfirmed) {
          handleNextStep();
        }
      });
    }
    if (isError) {
      setErrorMsg(
        error?.data?.ResultLanguages?.[getLanguage()] || error?.data?.Message
      );
    }
  }, [isSuccess, isError, isLoading]);

  useEffect(() => {
    if (account) setErrorMsg("");
  }, [account]);

  return (
    <>
      <Wrapper>
        {isLoading && <Loading />}
        <Container>
          <Title>{handleParseLanguage("register_mail_title")}</Title>
          <Info>{handleParseLanguage("register_mail_directions")}</Info>
          <FormEl>
            <FormContainer>
              <InputWrapper>
                <InputEl
                  value={account}
                  placeholder={`${handleParseLanguage(
                    "email"
                  )} (${handleParseLanguage("account")})`}
                  onChange={(e) => setAccount(e.target.value)}
                />
              </InputWrapper>
              <ErrorMsg>{errorMsg}</ErrorMsg>
            </FormContainer>
          </FormEl>
        </Container>
      </Wrapper>
      <Progress
        stepCount={stepCount}
        step={step}
        isValid={isValid}
        handleAction={handleSendMail}
      />
    </>
  );
};

export default Email;
