import styled, { css } from "styled-components";
import { QRCodeCanvas } from "qrcode.react";
import { useState, useEffect } from "react";
import { RWD_LG, RWD_SM } from "../../../utils/rwd";
import { useVerifyTelegramMutation } from "../../../services/authApi";
import Progress from "./Progress";

import { handleParseLanguage } from "../../../utils";
import { SuccessMySwal } from "../../../global/MySwal";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "../../../../components/Router";
import Loading from "../../../components/Loading/Loading";
import { getLanguage } from "../../../services/common";

const Wrapper = styled.div`
  display: flex;
`;

const Container = styled.div`
  width: clamp(280px, 40vw, 500px);
  flex: 1;
  display: flex;
  flex-direction: column;

  ${RWD_LG(css`
    width: 60vw;
  `)};

  ${RWD_SM(css`
    width: 85vw;
  `)};
`;

const Title = styled.h2`
  font-size: clamp(22px, 1.822vw, 35px);
`;

const Info = styled.div`
  margin-bottom: 20px;
  font-size: clamp(14px, 0.833vw, 16px);
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 5px;
  position: relative;
  align-items: center;
`;

const InputEl = styled.input`
  color: #fcfcfc;
  padding: 10px 10px;
  width: 100%;

  color: #fcfcfc;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */

  /* background: rgba(255, 255, 255, 0.1); */
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );

  &::placeholder {
    font-size: clamp(14px, 0.833vw, 16px);
  }
`;

const LabelEl = styled.label`
  /* font-size: 20px; */
`;

const FormEl = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Qrcode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const GetCode = styled.div`
  position: absolute;
  right: 15px;
  cursor: pointer;
  font-size: clamp(14px, 0.833vw, 16px);
`;

const ErrorMsg = styled.div`
  font-size: clamp(14px, 0.833vw, 15px);
  color: #f05d2f;
  height: 20px;
`;

const VerifyTelegram = ({ account, step, stepCount }) => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [redirect, setRedirect] = useState("");

  const isValid = verifyCode && verifyCode.length === 6;

  const [_verifyTelegram, { isSuccess, isError, error, isLoading }] =
    useVerifyTelegramMutation();

  const redirectUrl = `${process.env.REACT_APP_apiv2Host}/telegram/callback`;
  const url = `https://t.me/${
    process.env.REACT_APP_telegramBotName
  }?start=login&redirect_url=${encodeURIComponent(redirectUrl)}`;

  const handleSendVerifyCode = () => {
    window.open(url, "_blank");
  };

  // 判斷註冊完登入後需不需要導頁
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    setRedirect(query.get("redirect"));
  }, [search]);

  useEffect(() => {
    if (verifyCode) setErrorMsg("");
  }, [verifyCode]);

  const handleVerifyTelegram = async (e) => {
    e.preventDefault();
    await _verifyTelegram({
      body: {
        Account: account,
        VerifyCode: verifyCode,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      // 多國語言
      SuccessMySwal({
        title: handleParseLanguage("verification_successful"),
        showCancelButton: false,
        confirmButtonText: handleParseLanguage("confirm"),
      }).then((result) => {
        if (result.isConfirmed) {
          if (redirect) {
            navigate({
              pathname: `${routes.login_v2}?redirect=${redirect}`,
            });
          } else {
            navigate(routes.login_v2);
          }
        }
      });
    }

    if (isError) {
      setErrorMsg(
        error?.data?.ResultLanguages?.[getLanguage()] || error?.data?.Message
      );
    }
  }, [isSuccess, isError, isLoading]);

  return (
    <>
      <Wrapper>
        {isLoading && <Loading />}
        <Container>
          <Title>{handleParseLanguage("register_telegram_title")}</Title>
          <Info>{handleParseLanguage("register_telegram_directions")}</Info>
          <Qrcode>
            <QRCodeCanvas
              value={url}
              bgColor={"transparent"}
              fgColor={"#fcfcfc"}
              onClick={handleSendVerifyCode}
            />
          </Qrcode>

          <FormEl>
            <FormContainer>
              <InputWrapper>
                <InputEl
                  type="text"
                  placeholder={handleParseLanguage("verification_code")}
                  onChange={(e) => setVerifyCode(e.target.value)}
                  value={verifyCode}
                />

                <GetCode onClick={handleSendVerifyCode}>
                  {handleParseLanguage("get_code")}
                </GetCode>
              </InputWrapper>
              <ErrorMsg>{errorMsg}</ErrorMsg>
            </FormContainer>
          </FormEl>
        </Container>
      </Wrapper>
      <Progress
        step={step}
        stepCount={stepCount}
        isValid={isValid}
        handleAction={handleVerifyTelegram}
      />
    </>
  );
};

export default VerifyTelegram;
