import styled, { css } from "styled-components";
import { keyframes } from "styled-components";
import { RWD_LG, RWD_LL, RWD_MD, RWD_SM } from "../../../utils/rwd";
import { useState } from "react";

import Robot_hi from "../../../images/robot_hi.png";
import Robot_heart from "../../../images/robot_heart.png";
import Robot_phone from "../../../images/robot_phone.png";
import Robot_search from "../../../images/robot_search.png";
import Robot_write from "../../../images/robot_write.png";
import Robot_hand from "../../../images/robot_hand.png";

import { motion } from "framer-motion";
import { handleParseLanguage } from "../../../utils";

import { getLanguage } from "../../../services/common";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  color: #151944;
`;

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 50px;
  height: 70vh;
  width: clamp(500px, 40vw, 650px);
  margin-top: 10vh;
  padding: 40px;

  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-radius: 20px;

  ${RWD_SM(css`
    gap: 35px;
    width: 83.33vw;
    padding: 20px 15px;
    height: 58svh;
  `)};
`;

const ChatBubble = styled(motion.div)`
  position: relative;
  background-color: #fcfcfc;
  border-radius: 15px;
  width: fit-content;
  padding: 14px 20px;
  text-align: left;
  font-weight: bold;
  font-family: "Poppins", "Noto Sans TC", sans-serif;
  font-size: clamp(14px, 0.833vw, 16px);

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 20px;
    width: 0;
    height: 0;
    border: 23px solid transparent;
    border-top-color: #fcfcfc;
    border-bottom: 0;
    border-right: 0;
    margin-bottom: -15px;
  }

  ${RWD_SM(css`
    padding: 7px 15px;
  `)};
`;

const float = keyframes`
 
    0% {
        transform: translate3d(0, 0%, 0);
    }
    25% {
        transform: translate3d(0, 2%, 0);
    }
    75% {
        transform: translate3d(0, -2%, 0);
    }
    100% {
        transform: translate3d(0, 0%, 0);
    }

  
`;

const RobotWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 50px;
  width: clamp(100px, 10.4166vw, 200px);
  height: clamp(100px, 10.4166vw, 200px);
  right: 20%;
  /* background: rgba(255, 255, 255, 0.15); */
  padding: 10px;
  animation: ${float} 4s linear infinite;

  img {
    position: absolute;
  }

  ${RWD_LG(css`
    bottom: 10vh;
  `)};

  ${RWD_SM(css`
    right: 8.5vw;
    bottom: 15svh;
  `)};
`;

const Close = styled(motion.button)`
  margin: 0 auto;
  padding: 10px 15px;
  position: absolute;
  align-self: center;
  bottom: 10vh;
  min-width: 120px;
  border-radius: 60px;
  color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
  background: ${(props) =>
    props.type === "confirm"
      ? props.disabled
        ? "#3A3D5A"
        : "linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%)"
      : ""};
  border-color: ${(props) => (props.disabled ? "#d9d9d9" : "")};
  display: flex;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  ${RWD_SM(css`
    bottom: 8svh;
  `)};
`;

const DirectionModal = ({ setIsShow, step }) => {
  const handleCloseModal = () => {
    setIsShow(false);
  };

  const stepMessage = [
    {
      id: 1,
      messageList: [
        {
          ch: "您好，歡迎加入AVA！",
          en: "Hello, welcome to AVA!",
        },
        {
          ch: "我是小V 😄",
          en: " I am V. 😄",
        },
        {
          ch: "請在輸入框中輸入電子信箱後點擊下一步按鈕",
          en: "Please enter your email in the input box and click the next button.",
        },
        {
          ch: "我們將會發送驗證碼至您的電子信箱",
          en: "We will send a verification code to your email.",
        },
      ],
      robot: Robot_hi,
    },
    {
      id: 2,
      messageList: [
        {
          ch: "我們已經將驗證碼發送至電子信箱了喔！",
          en: "We have sent the verification code to your email!",
        },
        {
          ch: "請至電子信箱收信後輸入驗證碼",
          en: "Please check your email and enter the verification code.",
        },
      ],

      robot: Robot_phone,
    },
    {
      id: 3,
      messageList: [
        {
          ch: "請設定一組容易記住但別人難以破解的密碼",
          en: "Please set a password that is easy to remember but difficult for others to crack.",
        },
        {
          ch: "密碼長度需為8-20個字元",
          en: "The password length must be 8-20 characters.",
        },
        {
          ch: "並包含至少1個數字和1個字母",
          en: "And include at least 1 number and 1 letter.",
        },
      ],
      robot: Robot_search,
    },
    {
      id: 4,
      messageList: [
        {
          ch: "請先選擇國碼",
          en: "Please select the country code first.",
        },
        {
          ch: "再輸入您的手機號碼",
          en: "Then enter your phone number.",
        },
        {
          ch: "如果有推薦人，請輸入推薦人的推薦碼",
          en: "If you have a referrer, please enter the referrer's referral code.",
        },
      ],
      robot: Robot_write,
    },
    {
      id: 5,
      messageList: [
        {
          ch: "請掃描QR Code",
          en: "Please scan the QR Code",
        },
        {
          ch: "綁定Telegram，以便接收通知",
          en: "Bind Telegram, so you can receive notifications.",
        },
        {
          ch: "完成後點擊下一步就可以開始使用AVA了喔！",
          en: "After completing, click next to start using AVA!",
        },
      ],
      robot: Robot_heart,
    },
  ];

  const bubbleVariants = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 1,
      },
    },
  };

  const bubbleItemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const buttonVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <Wrapper>
      <Container variants={bubbleVariants} initial="hidden" animate="visible">
        {stepMessage
          .filter((data) => data.id === step)?.[0]
          ?.messageList.map((msg, index) => (
            <ChatBubble key={index} variants={bubbleItemVariants}>
              {msg[getLanguage()?.toLowerCase()]}
            </ChatBubble>
          ))}
        <Close
          type="confirm"
          variants={buttonVariants}
          onClick={handleCloseModal}
        >
          {handleParseLanguage("gotit")}
        </Close>
      </Container>

      <RobotWrapper>
        <img
          src={stepMessage.filter((data) => data.id === step)?.[0].robot}
          alt="robot"
        />
      </RobotWrapper>
    </Wrapper>
  );
};

export default DirectionModal;
