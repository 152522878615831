import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useDepositTransactionRecordQuery } from "../../services/walletApi";
import Lang from "../../global/Lang";
import { selectSystem } from "../../slices/systemSlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ------ Styled Components ------
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  color: #151944;
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
`;

const TableWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #151944;
  color: #fcfcfc;
  padding: 10px 0;
  position: sticky;
  min-width: 800px;
`;

const Th = styled.div`
  font-weight: 400;
  text-align: center;
  font-weight: bold;
`;

const ListBox = styled.div`
  border: 1px solid #151944;
  overflow-y: auto;
  max-height: 60vh;
`;

const ListBody = styled.div``;

const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: #151944;
  min-width: 800px;
  background-color: ${(props) => (props.index % 2 === 0 ? "#D9D9D9" : "#fcfcfc")};

`;

const ListItem = styled.div`
  padding: 8px;
  text-align: center;
`;

const ListItemText = styled.div`
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  width: 100%;
`;

const Nodata = styled.div`
  padding: 20px;
  text-align: center;
  color: #151944;
`;

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    background-color: rgb(30, 34, 64); 
    color: #fff;
    font-size: 16px; 
    border-radius: 8px; 
  }

  .Toastify__toast--success {
    background-color: rgba(30, 34, 64,0.9);
  }

  .Toastify__toast--error {
    background-color: #dc3545;
  }

  .Toastify__progress-bar {
    background-color: #0aecd1;
  }
`;

const DepositHistory = () => {
    const lang = useSelector(selectSystem).language;
    const [language, setLanguage] = useState(lang);
    const [dataList, setDataList] = useState([]);
    const {
        data,
        isSuccess,
        refetch
    } = useDepositTransactionRecordQuery();

    useEffect(() => {
        setLanguage(lang);
    }, [lang])

    useEffect(() => {
        refetch()
    }, [])


    useEffect(() => {
        if (isSuccess) {
            setDataList(data?.Transactions.map((item) => ({
                time: item.CreateTime,
                amount: item.Amount,
                status: item.Success ? "Success" : "Failed",
                txHash: item.TxHash,
                currencyId: item.CurrencyID,
            })));
        }
    }, [data, isSuccess]);

    const tableHeader = [
        {
            id: 1,
            name: "Date",
            value: "time",
            width: "15%",
        },

        {
            id: 2,
            name: "Amount",
            value: "amount",
            width: "10%",
        },
        {
            id: 3,
            name: "Status",
            value: "status",
            width: "20%",
        },
        {
            id: 4,
            name: "TXID",
            value: "txHash",
            width: "15%",
            render: (row) => (
                <div>
                    <button
                        style={{
                            marginLeft: "8px",
                            padding: "4px 8px",
                            backgroundColor: "#151944",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            navigator.clipboard.writeText(row.txHash);
                            toast.success(language === "En" ? "Copied!" : "複製成功！", {
                                position: "bottom-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }}
                    >
                        Copy
                    </button>
                </div>
            ),
        },
    ];

    return (
        <Wrapper>
            <StyledToastContainer />
            <Title>
                <Lang datakey="deposit_history" defaultText="Deposit History" />
            </Title>
            <ListBox>
                <TableWrapper>
                    {tableHeader.map((header) => (
                        <Th key={header.id} style={{ width: header.width }}>
                            {header.name}
                        </Th>
                    ))}
                </TableWrapper>
                {dataList.length > 0 ? (
                    <ListBody>
                        {dataList.map((item, index) => (
                            <ListContainer key={item.currencyId} index={index + 1}>
                                {tableHeader.map((header) => (
                                    <ListItem key={header.id} style={{ width: header.width }}>
                                        <ListItemText>
                                            {header.render
                                                ? header.render(item)
                                                : item[header.value]}
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </ListContainer>
                        ))}
                    </ListBody>
                ) : (
                    <Nodata>
                        <Lang datakey="nodata" defaultText="No data available" />
                    </Nodata>
                )}

            </ListBox>
        </Wrapper>
    );
};

export default DepositHistory;
