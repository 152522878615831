import styled from "styled-components";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Fragment, useState } from "react";
import { getLanguage } from "../services/common";
import Icons from "./icons";
import Logo from "../images/logo512.png";
import { ChekcUserAgent } from "../utils/index";

const MySwal = withReactContent(Swal);

const Wrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConfirmButton = styled.button`
  border: 1px solid #22254a;
  background-color: #22254a;
  padding: 10px 20px;
  border-radius: 0.25rem;
  color: #fcfcfc;
  min-width: 120px;
`;

const CancelButton = styled.button`
  border: 1px solid #22254a;
  padding: 10px 20px;
  border-radius: 0.25rem;
  color: #22254a;
  margin-right: 10px;
  min-width: 120px;
`;

const DenyButton = styled.button`
  border: 1px solid #d9d9d9;
  padding: 10px 20px;
  border-radius: 0.25rem;
  background-color: #d9d9d9;
  margin-right: 10px;
  color: #22254a;
  min-width: 120px;
`;

const TitleWrapper = styled.div``;

const Title = styled.div`
  font-weight: bold;
  font-size: min(max(1.5625vw, 28px), 30px);
`;

const ContentWrapper = styled.div`
  padding: 0 0 30px 0;
  color: #22254a;
`;

const TextWrapper = styled.div`
  padding-top: 10px;
`;

const Text = styled.div`
  font-size: min(max(1.145vw, 18px), 20px);
`;

const FooterWrapper = styled.div`
  color: #3fc3ee;
  cursor: pointer;
  font-weight: 900;
`;

const TelegramWrapper = styled.div`
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 15px;
`;

const CheckboxContainer = styled.div`
  font-size: min(max(0.9375vw, 16px), 18px);
  padding-left: 10px;
`;

const CheckboxWrapper = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.verified ? "#151944" : "#D9D9D9")};
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  accent-color: #151944;
  width: 20px;
  height: 20px;
  margin: 0;
`;

const RedirectWrapper = styled.div`
  padding-top: 10px;
  cursor: pointer;
`;

const ImgWrapper = styled.div`
  display: inline-flex;
  padding: 40px 0;

  svg {
    width: 100%;
    height: 200px;
  }
`;

const InstallDescriptionWrapper = styled.div``;

const InstallDescription = styled.div``;

const InstallStepWrapper = styled.ul`
  /* display: flex;
  flex-direction: column;
  gap: 10px; */
  border-top: 1px solid #ccc;
  padding-top: 20px;
  list-style-position: outside;
`;

const InstallStep = styled.li`
  text-align: left;
  padding-bottom: 10px;
`;

const InstallStepIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 5px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const AppIconWrapper = styled.div`
  display: inline-flex;
  padding: 20px 0;

  img {
    width: 100px;
    height: 100px;
  }
`;

const CountdownWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CountdownText = styled.span`
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
`;

export const SuccessMySwal = ({
  title,
  text,
  showCancelButton,
  cancelButtonText,
  confirmButtonText,
}) => {
  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#22254a",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    icon: "success",
    allowOutsideClick: false,
    html: (
      <Wrapper>
        <ContentWrapper>
          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>
          {text && (
            <TextWrapper>
              <Text>{text}</Text>
            </TextWrapper>
          )}
        </ContentWrapper>

        <Fragment>
          {showCancelButton ? (
            <ButtonWrapper>
              <CancelButton onClick={() => MySwal.clickCancel()}>
                {cancelButtonText}
              </CancelButton>
              <ConfirmButton onClick={() => MySwal.clickConfirm()}>
                {confirmButtonText}
              </ConfirmButton>
            </ButtonWrapper>
          ) : (
            <ConfirmButton onClick={() => MySwal.clickConfirm()}>
              {confirmButtonText}
            </ConfirmButton>
          )}
        </Fragment>
      </Wrapper>
    ),
  });
};

export const CountdownMySwal = ({ title, text, countdownSeconds }) => {
  let countdown = countdownSeconds;

  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#22254a",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    icon: "success",
    allowOutsideClick: false,
    html: (
      <Wrapper>
        <ContentWrapper>
          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>
          {text && (
            <TextWrapper>
              <Text>{text}</Text>
            </TextWrapper>
          )}
          <CountdownWrapper>
            <CountdownText id="countdown-timer">
              {countdown} s
            </CountdownText>
          </CountdownWrapper>
        </ContentWrapper>
      </Wrapper>
    ),
    didOpen: () => {
      const timer = setInterval(() => {
        countdown -= 1;
        const countdownElement = document.getElementById("countdown-timer");
        if (countdownElement) {
          countdownElement.textContent = `${countdown} s`; // 更新倒數數字
        }
        if (countdown <= 0) {
          clearInterval(timer); // 倒數完成
          MySwal.close(); // 關閉 Swal
        }
      }, 1000);
    },
  });
};

export const showCountdownSwal = ({ title, text, countdownSeconds = 60 }) => {
  let countdown = countdownSeconds; // 倒數秒數

  return Swal.fire({
    title: title || "Creating Wallet...",
    html: `
      <div style="display: flex; flex-direction: column; align-items: center;">
        <div id="custom-icon" style="width: 80px; height: 80px; margin-bottom: 10px;">
        <svg viewBox="0 0 100 100" style="width: 100%; height: 100%;">
        <circle cx="50" cy="50" r="45" stroke="#ccc" stroke-width="4" fill="none" />
        <line id="clock-hand" x1="50" y1="50" x2="50" y2="15" 
              stroke="#007bff" stroke-width="4" transform-origin="50% 50%"
              style="transform: rotate(${360 - (countdownSeconds / 60) * 360}deg);" />
      </svg>
        </div>
        <p style="margin-top: 10px;">${text || "Please wait until the countdown is complete."}</p>
        <h2 id="swal-countdown" style="font-size: 1.5rem; margin-top: 5px;">${countdown} s</h2>
      </div>
    `,
    showConfirmButton: false,
    allowOutsideClick: false,
    didOpen: () => {
      const countdownElement = document.getElementById("swal-countdown");
      const clockHand = document.getElementById("clock-hand");

      const timer = setInterval(() => {
        countdown -= 1;

        if (countdownElement) {
          countdownElement.textContent = `${countdown} s`; // 更新倒數數字
        }

        if (clockHand) {
          // 每秒旋轉 6 度 (360 度 / 60 秒)
          clockHand.style.transform = `rotate(${(60 - countdown) * 6}deg)`;
        }

        if (countdown <= 0) {
          clearInterval(timer); // 清除計時器
          Swal.close(); // 倒數結束，關閉彈窗
        }
      }, 1000);
    },
  });
};


export const ErrorMySwal = ({ title, text }) => {
  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#22254a",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    icon: "error",
    allowOutsideClick: false,
    html: (
      <Wrapper>
        <ContentWrapper>
          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>
          {text && (
            <TextWrapper>
              <Text>{text}</Text>
            </TextWrapper>
          )}
        </ContentWrapper>
        <ConfirmButton onClick={() => MySwal.clickConfirm()}>
          {getLanguage() === "Ch" ? "確認" : "Confirm"}
        </ConfirmButton>
      </Wrapper>
    ),
  });
};

export const FooterMySwal = ({ title, text, footerText, footerFun }) => {
  const handleSwal = () => {
    footerFun();
    MySwal.clickConfirm();
  };

  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#22254a",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    icon: "error",
    allowOutsideClick: false,
    html: (
      <Wrapper>
        <ContentWrapper>
          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>
          {text && (
            <TextWrapper>
              <Text>{text}</Text>
            </TextWrapper>
          )}
        </ContentWrapper>
        <ConfirmButton onClick={() => MySwal.clickConfirm()}>
          {getLanguage() === "Ch" ? "確認" : "Confirm"}
        </ConfirmButton>
      </Wrapper>
    ),
    footer: (
      <Fragment>
        <FooterWrapper onClick={handleSwal}>{footerText}</FooterWrapper>
      </Fragment>
    ),
  });
};

export const WarningMySwal = ({
  title,
  text,
  showCancelButton,
  cancelButtonText,
  confirmButtonText,
  onConfirm,
  onCancel,
}) => {
  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#22254a",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    icon: "warning",
    allowOutsideClick: false,
    html: (
      <Wrapper>
        <ContentWrapper>
          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>
          {text && (
            <TextWrapper>
              <Text>{text}</Text>
            </TextWrapper>
          )}
        </ContentWrapper>

        <Fragment>
          {showCancelButton ? (
            <ButtonWrapper>
              <CancelButton onClick={() => {
                if (onCancel) onCancel();
                MySwal.clickCancel();
              }}>
                {cancelButtonText}
              </CancelButton>
              <ConfirmButton onClick={() => {
                if (onConfirm) onConfirm();
                MySwal.clickConfirm();
              }}>
                {confirmButtonText}
              </ConfirmButton>
            </ButtonWrapper>
          ) : (
            <ConfirmButton onClick={() => {
              if (onConfirm) onConfirm();
              MySwal.clickConfirm();
            }}>
              {confirmButtonText}
            </ConfirmButton>
          )}
        </Fragment>
      </Wrapper>
    ),
  });
};

export const AlertMySwal = ({ title, text }) => {
  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#22254a",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    icon: "success",
    timer: 1500,
    html: (
      <Wrapper>
        <ContentWrapper>
          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>
          {text && (
            <TextWrapper>
              <Text>{text}</Text>
            </TextWrapper>
          )}
        </ContentWrapper>
      </Wrapper>
    ),
  });
};

export const InfoMySwal = ({
  title,
  text,
  showCancelButton,
  cancelButtonText,
  confirmButtonText,
}) => {
  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#22254a",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    icon: "info",
    allowOutsideClick: false,
    html: (
      <Wrapper>
        <ContentWrapper>
          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>
          {text && (
            <TextWrapper>
              <Text>{text}</Text>
            </TextWrapper>
          )}
        </ContentWrapper>

        <Fragment>
          {showCancelButton ? (
            <ButtonWrapper>
              <CancelButton onClick={() => MySwal.clickCancel()}>
                {cancelButtonText}
              </CancelButton>
              <ConfirmButton onClick={() => MySwal.clickConfirm()}>
                {confirmButtonText}
              </ConfirmButton>
            </ButtonWrapper>
          ) : (
            <ConfirmButton onClick={() => MySwal.clickConfirm()}>
              {confirmButtonText}
            </ConfirmButton>
          )}
        </Fragment>
      </Wrapper>
    ),
  });
};

export const CreateAlgoMySwal = ({
  title,
  text,
  showCancelButton,
  cancelButtonText,
  confirmButtonText,
  checkBoxText,
  verifyText,
  telegramVerified,
  className,
}) => {
  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#22254a",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    icon: "question",
    allowOutsideClick: false,
    customClass: className,

    html: (
      <Wrapper>
        <ContentWrapper>
          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>
          {text && (
            <TextWrapper>
              <Text>{text}</Text>
            </TextWrapper>
          )}
          <TelegramWrapper>
            <CheckboxWrapper verified={telegramVerified}>
              <CheckBox
                type="checkbox"
                id="tg"
                disabled={!telegramVerified}
              ></CheckBox>

              <CheckboxContainer>{checkBoxText}</CheckboxContainer>
            </CheckboxWrapper>
            {!telegramVerified && (
              <RedirectWrapper>{verifyText}</RedirectWrapper>
            )}
          </TelegramWrapper>
        </ContentWrapper>

        <Fragment>
          {showCancelButton ? (
            <ButtonWrapper>
              <CancelButton onClick={() => MySwal.clickCancel()}>
                {cancelButtonText}
              </CancelButton>
              <ConfirmButton onClick={() => MySwal.clickConfirm()}>
                {confirmButtonText}
              </ConfirmButton>
            </ButtonWrapper>
          ) : (
            <ConfirmButton onClick={() => MySwal.clickConfirm()}>
              {confirmButtonText}
            </ConfirmButton>
          )}
        </Fragment>
      </Wrapper>
    ),
    preConfirm: function () {
      const tg = Swal.getPopup().querySelector("#tg").checked;
      return [
        {
          subscribeStatus: tg,
        },
      ];
    },
  });
};

export const ToggleAlgoMySwal = ({
  title,
  text,
  denyButtonText,
  cancelButtonText,
  confirmButtonText,
}) => {
  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    showDenyButton: false,
    confirmButtonColor: "#22254a",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    icon: "info",
    allowOutsideClick: false,

    html: (
      <Wrapper>
        <ContentWrapper>
          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>
          {text && (
            <TextWrapper>
              <Text>{text}</Text>
            </TextWrapper>
          )}
        </ContentWrapper>

        <Fragment>
          <ButtonWrapper>
            <CancelButton onClick={() => MySwal.clickCancel()}>
              {cancelButtonText}
            </CancelButton>
            <DenyButton onClick={() => MySwal.clickDeny()}>
              {denyButtonText}
            </DenyButton>
            <ConfirmButton onClick={() => MySwal.clickConfirm()}>
              {confirmButtonText}
            </ConfirmButton>
          </ButtonWrapper>
        </Fragment>
      </Wrapper>
    ),
  });
};

export const TutorialMySwal = ({
  title,
  text,
  showCancelButton,
  cancelButtonText,
  confirmButtonText,
}) => {
  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#22254a",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    allowOutsideClick: false,
    html: (
      <Wrapper>
        <ContentWrapper>
          <ImgWrapper>
            <Icons.Tutorial_Teaching />
          </ImgWrapper>

          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>
          {text && (
            <TextWrapper>
              <Text>{text}</Text>
            </TextWrapper>
          )}
        </ContentWrapper>

        <Fragment>
          {showCancelButton ? (
            <ButtonWrapper>
              <CancelButton onClick={() => MySwal.clickCancel()}>
                {cancelButtonText}
              </CancelButton>
              <ConfirmButton onClick={() => MySwal.clickConfirm()}>
                {confirmButtonText}
              </ConfirmButton>
            </ButtonWrapper>
          ) : (
            <ConfirmButton onClick={() => MySwal.clickConfirm()}>
              {confirmButtonText}
            </ConfirmButton>
          )}
        </Fragment>
      </Wrapper>
    ),
  });
};

export const PwaMySwal = ({ title, text, confirmButtonText }) => {
  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#22254a",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    allowOutsideClick: false,
    html: (
      <Wrapper>
        <ContentWrapper>
          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>
          <InstallDescriptionWrapper>
            <AppIconWrapper>
              <img src={Logo} />
            </AppIconWrapper>
            {/* <InstallDescription>
              For the best experience, we recommend nstall a Progressive Web
              Application to your home screen
            </InstallDescription> */}
          </InstallDescriptionWrapper>

          {text && (
            <TextWrapper>
              <Text>{text}</Text>
            </TextWrapper>
          )}
          <InstallStepWrapper>
            {ChekcUserAgent() === "iOS" && (
              <InstallStep>* Please use Safari browser.</InstallStep>
            )}

            <InstallStep>
              1. Click the share icon
              <InstallStepIcon>
                <Icons.Share />
              </InstallStepIcon>
            </InstallStep>
            <InstallStep>
              2. Scroll down and then click "Add to Home Screen"
            </InstallStep>
          </InstallStepWrapper>
        </ContentWrapper>

        <Fragment>
          <ConfirmButton onClick={() => MySwal.clickConfirm()}>
            {confirmButtonText}
          </ConfirmButton>
        </Fragment>
      </Wrapper>
    ),
  });
};
