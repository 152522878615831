import styled, { css } from "styled-components";
import { RWD_LG, RWD_MD, RWD_SM } from "../../../utils/rwd";
import { routes } from "../../../../components/Router";
import { useNavigate, useLocation } from "react-router-dom";
import { handleParseLanguage } from "../../../utils";
import { useState, useEffect } from "react";

const StepWrapper = styled.div`
  margin-bottom: 5vh;

  ${RWD_SM(css`
    margin-bottom: 6svh;
  `)};
`;
const ProgressList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 50px;

  ${RWD_SM(css`
    margin-bottom: 30px;
  `)};
`;

const ProgressItem = styled.li`
  width: ${(props) => (props.isActive ? "15px" : " 6px")};
  height: 6px;
  border-radius: ${(props) => (props.isActive ? "10px" : " 50%")};
  background: ${(props) =>
    props.isActive ? "#2f80ed" : "rgba(255, 255, 255, 0.15)"};
  transition: all 0.3s;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: ${(props) =>
    props.hasTwoButtons ? "space-between" : "center"};
`;

const Button = styled.button`
  min-width: 120px;
  border-radius: 60px;
  color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
  background: ${(props) =>
    props.type === "confirm"
      ? props.disabled
        ? "#3A3D5A"
        : "linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%)"
      : ""};
  border-color: ${(props) =>
    props.type === "default" ? "#fcfcfc" : props.disabled ? "#d9d9d9" : ""};
  display: flex;
  justify-content: center;
  border-width: ${(props) => (props.type === "default" ? "1px" : "")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

const ButtonText = styled.span`
  padding: 10px 16px;
  text-align: center;
  font-size: clamp(14px, 1.0416vw, 20px);
  color: #fcfcfc;
`;

const Progress = ({ stepCount, step, handleAction, isValid }) => {
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState("");

  // 判斷註冊完登入後需不需要導頁
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    setRedirect(query.get("redirect"));
  }, [search]);

  const handleRedirectToHome = () => {
    navigate(routes.landing);
  };
  const handleRedirectToLogin = () => {
    if (redirect) {
      navigate({
        pathname: `${routes.login_v2}?redirect=${redirect}`,
      });
    } else {
      navigate(routes.login_v2);
    }
  };

  return (
    <StepWrapper>
      <ProgressList>
        {Array.from(
          {
            length: stepCount,
          },
          (v, i) => i
        ).map((item, index) => (
          <ProgressItem key={index} isActive={index + 1 === step} />
        ))}
      </ProgressList>
      <ButtonWrapper hasTwoButtons={step === 1 || step === 5 ? true : false}>
        {step === 1 && (
          <Button type="default" onClick={handleRedirectToHome}>
            <ButtonText>{handleParseLanguage("home")}</ButtonText>
          </Button>
        )}

        {step === 5 && (
          <Button type="default" onClick={handleRedirectToLogin}>
            <ButtonText>{handleParseLanguage("login")}</ButtonText>
          </Button>
        )}

        <Button type="confirm" onClick={handleAction} disabled={!isValid}>
          <ButtonText>
            {step === 5
              ? handleParseLanguage("verify")
              : handleParseLanguage("next")}
          </ButtonText>
        </Button>
      </ButtonWrapper>
    </StepWrapper>
  );
};

export default Progress;
