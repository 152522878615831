import styled, { css } from "styled-components";
import { useState, useEffect } from "react";
import Pagination from "./components/Pagination";
import SelectDataCenter from "../../global/SelectDataCenter";
import Lang from "../../global/Lang";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  useGetAutoGridRobotSymbolsQuery,
  useFetchAutoGridRobotHistoryMutation,
} from "../../services/miningApi";
import { ErrorMySwal } from "../../global/MySwal";
import Loading from "../../components/Loading/Loading";
import moment from "moment";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

// 多國語言
import { getLanguage } from "../../services/common";

const Wrapper = styled.div`
  /* border-radius: 8px; */
  /* padding: 20px; */
  padding: 20px 0;

  /* background-color: #3a3d5a; */
  /* background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  ); */
`;

const Container = styled.div`
  color: #fcfcfc;
`;

const Title = styled.div`
  padding-bottom: 20px;
  font-size: min(max(1.25vw, 22px), 24px);
`;

const CurrentStatusWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`;

const CurrencySettingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 45%;
  gap: 20px;
`;

const CurrencySelector = styled.div`
  width: 100%;
`;
const CurrentHoldingsInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  // align-items: center;
  background-color: #3a3d5a;
  padding: 20px;
  height: 100%;
`;

const Box = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
`;

const KeyWrapper = styled.div`
  color: #0aecd1;
`;

const ValueWrapper = styled.div`
  font-size: 1.6vw;
  font-weight: bold;
  display: flex;
  width: 100%;
  align-items: baseline;
  // justify-content: center;
  gap: 10px;
  // padding-top: 40px;
`;

const ProfitValue = styled.span`
  color: ${(props) => (props.profit > 0 ? "#1cac2a" : "#F44336")};
`;

const ValueSymbol = styled.span`
  font-size: 15px;
  margin-left: 3px;
`;

const Value = styled.div`
  color: #1cac2a;
  font-size: min(max(1.145vw, 20px), 22px);

  color: ${({ $status }) => ($status ? "#1cac2a" : "#f44336")};
`;

const ChartWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;

  /* background-color: #3a3d5a; */
  /* padding: 20px; */
  background-color: #3a3d5a;
  border-radius: 8px;
  padding: 20px;

  /* border-bottom: 1px solid #54587b; */
`;

const ChartContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const ChartTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  flex-wrap: wrap;
`;

const DateSelector = styled.ul`
  display: flex;
  gap: 20px;
`;

const DateItem = styled.li`
  cursor: pointer;
  color: ${({ $active }) => ($active ? "#0aecd1" : "#fcfcfc")};
  :hover {
    color: #0aecd1;
  }
`;

const NextSettlementDate = styled.div`
  display: flex;
  gap: 10px;
  font-size: 14px;
`;

const NextSettlementDateTitle = styled.div`
  opacity: 0.8;
`;

const Date = styled.div`
  color: #0aecd1;
`;

const SettlementRecordsWrapper = styled.div`
  /* background-color: #3a3d5a; */
  /* padding: 20px 20px 40px 20px; */
  border-radius: 8px;
  margin-top: 20px;
`;

const SettlementRecordsContainer = styled.div`
  background-color: #3a3d5a;
  padding: 20px;
  border-radius: 8px;
`;

const SettlementRecordsTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const SettlementRecordsTitle = styled.div``;

const SettlementRecordsMonthSelector = styled.div`
  width: 100%;
`;

const TableEl = styled.table`
  width: 100%;
  table-layout: fixed;
`;

const TableContainer = styled.div``;

const TheadEl = styled.thead`
  color: rgba(255, 255, 255, 0.5);
`;

const ThEl = styled.th`
  padding: 0px 10px 15px 10px;
  font-weight: normal;
`;

const TbodyEl = styled.tbody`
  overflow-x: scroll;
`;

const TrEl = styled.tr`
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
`;

const TdEl = styled.td`
  padding: 15px 10px;
`;

const TooltipWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  font-size: 12px;
  color: #fcfcfc;
  font-family: Montserrat;
  align-items: center;
`;

const TooltipIcon = styled.div`
  width: 10px;
  height: 10px;
  background: ${({ $color }) => $color};
`;

const TooltipLabelWrapper = styled.div`
  padding-bottom: 5px;
  text-align: center;
`;

const TooltipValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TooltipValueTitle = styled.div`
  padding: 0 8px;
`;

const TooltipValue = styled.div`
  padding-left: 10px;
`;

const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  // border:1px solid red;
  padding-bottom: 20px;

  /* overflow-x: auto; */
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;

  ${RWD_SM(css`
    width: 33%;
  `)};
`;

const TabItem = styled.div`
  min-width: 100px;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  text-align: center;
  padding: 8px 0px;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${({ $activeTab }) =>
    $activeTab ? "#0AECD1" : "rgba(255, 255, 255, 0.15)"};

  cursor: pointer;
  font-size: min(max(0.833vw, 14px), 16px);
  text-transform: capitalize;
  padding: 10px 0;
  width: 100%;
  height: 100%;

  ${RWD_SM(css`
    min-width: auto;
    padding: 10px 1.3vw;
    /* margin-right: 5px; */
  `)};
`;

const ToolBar = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const ToolBarContainer = styled.div`
  display: flex;
  // border:1px solid red;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
  width: 56%;
`;

const Nodata = styled.div`
  text-align: center;
  /* font-weight: bold; */
  font-size: 20px;
  padding: 20px;
  color: rgba(255, 255, 255, 0.5);
`;

const Chart = ({ chartData }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (chartData.length > 0) {
      setData(
        chartData.map((item) => {
          return {
            ...item,
            CurrentHoldingPercent: Number(
              item.CurrentHoldingPercent.split("%")[0]
            ),
          };
        })
      );
    }
  }, [chartData]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <TooltipWrapper className="custom-tooltip">
          <TooltipLabelWrapper className="label">
            {label?.split(" ")[0]}
          </TooltipLabelWrapper>
          <TooltipValueWrapper className="intro">
            <TooltipIcon $color={payload[0]?.stroke}></TooltipIcon>
            <TooltipValueTitle>{payload[0]?.name}</TooltipValueTitle>
            <TooltipValue>{Number(payload[0]?.value).toFixed(2)}%</TooltipValue>
          </TooltipValueWrapper>
        </TooltipWrapper>
      );
    }

    return null;
  };

  const formatXAxisDate = (date) => {
    return `${date?.split(" ")[0].split("-").slice(1).join("/")}`;
  };

  const formatYAxisValue = (value) => `${value}%`;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={1000}
        height={300}
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <Line
          type="monotone"
          dataKey="CurrentHoldingPercent"
          stroke="#2F80ED"
          activeDot={{ r: 4 }}
          name={
            <Lang
              datakey="holdings_change_trend"
              defaultText="Holdings Change Trend"
            />
          }
        />
        <CartesianGrid opacity={0.1} />
        <XAxis
          dataKey="TradeTime"
          dy={10}
          tickFormatter={formatXAxisDate}
          stroke="rgba(255, 255, 255, 0.8)"
          style={{
            fontSize: "13px",
            fontFamily: "Montserrat",
          }}
        />
        <YAxis
          stroke="rgba(255, 255, 255, 0.8)"
          tickFormatter={formatYAxisValue}
          style={{
            fontSize: "13px",
            fontFamily: "Montserrat",
          }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          verticalAlign="top"
          height={25}
          wrapperStyle={{ fontSize: "11px", fontFamily: "Montserrat" }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const MiningCoinFuture = ({ packageId, groudId }) => {
  const [currentHolding, setCurrentHolding] = useState("");
  const [currentHoldingPercent, setCurrentHoldingPercent] = useState("");
  const [currentHoldingAfterSettlement, setCurrentHoldingAfterSettlement] =
    useState("");
  const [
    currentHoldingAfterSettlementPercent,
    setCurrentHoldingAfterSettlementPercent,
  ] = useState("");

  // 當前持有 （初始）
  const [initialAmount, setInitialAmount] = useState("");

  // 當前持有 （合約已實現）
  const [spotAmount, setSpotAmount] = useState("");

  // 當前持有 （現貨購買）
  const [profitAmount, setProfitAmount] = useState("");

  // 增量持倉(量/均價/損益)
  const [remainHoldingInfo, setRemainHoldingInfo] = useState({});

  // 交易持倉(量/均價/損益)
  const [holdingTradeInfo, setHoldingTradeInfo] = useState({});

  const [holdingsChangeTrend, setHoldingsChangeTrend] = useState([]);
  const [settlementRecords, setSettlementRecords] = useState([]);

  const [month, setMonth] = useState(moment().month() + 1);
  const [currency, setCurrency] = useState("");
  const [currencyList, setCurrencyList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const monthData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [historyType, setHistoryType] = useState("Future");
  const historyTypeList = [
    {
      key: "Future",
      lang: <Lang datakey="future" defaultText="Future" />,
    },
    {
      key: "Spot",
      lang: <Lang datakey="spot" defaultText="Spot" />,
    },
  ];

  // Pagination 計算
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  // 計算當前頁面
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = settlementRecords.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  // 總頁數
  const totalPages = Math.ceil(settlementRecords.length / recordsPerPage);

  // 換頁
  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // 切換Tab
  const tabClick = (key) => {
    setHistoryType(key);
    setCurrentPage(1);
  };

  const {
    data: autoGridRobotSymbolsData,
    isSuccess: autoGridRobotSymbolsIsSuccess,
    isError: autoGridRobotSymbolsIsError,
    error: autoGridRobotSymbolsError,
  } = useGetAutoGridRobotSymbolsQuery(packageId);

  const [
    _fetchAutoGridRobotHistory,
    {
      data: fetchAutoGridRobotHistoryData,
      isSuccess: fetchAutoGridRobotHistoryIsSuccess,
      isError: fetchAutoGridRobotHistoryIsError,
      error: fetchAutoGridRobotHistoryError,
      isLoading: fetchAutoGridRobotHistoryIsLoading,
    },
  ] = useFetchAutoGridRobotHistoryMutation();

  useEffect(() => {
    if (autoGridRobotSymbolsIsSuccess) {
      setCurrencyList(
        autoGridRobotSymbolsData.SymbolList.map((item) => {
          return {
            Key: item,
            UnitValue: item,
            Value: item,
          };
        })
      );

      setCurrency(autoGridRobotSymbolsData.SymbolList[0]);
    }

    if (autoGridRobotSymbolsIsError) {
      ErrorMySwal({
        title:
          autoGridRobotSymbolsError?.data?.ResultLanguages[getLanguage()] ||
          autoGridRobotSymbolsError?.data?.Message,
      });
    }
  }, [autoGridRobotSymbolsData, autoGridRobotSymbolsIsError]);

  useEffect(() => {
    if (groudId && month && currency && historyType) {
      _fetchAutoGridRobotHistory({
        AhftGroupId: groudId,
        Month: month,
        Asset: currency,
        HistoryType: historyType,
      });
    }
  }, [groudId, month, currency, historyType]);

  useEffect(() => {
    if (fetchAutoGridRobotHistoryIsSuccess) {
      // 當前持有 （初始）
      setInitialAmount(fetchAutoGridRobotHistoryData?.InitialAmount);
      console.log(initialAmount);

      setSpotAmount(fetchAutoGridRobotHistoryData?.SpotAmount);
      console.log(spotAmount);

      setProfitAmount(fetchAutoGridRobotHistoryData?.ProfitAmount);
      console.log(profitAmount);

      // setCurrentHoldingPercent(
      //   fetchAutoGridRobotHistoryData?.CurrentHoldingPercent?.split("%")[0]
      // );

      // 交易持倉(量/均價/損益)
      setHoldingTradeInfo(fetchAutoGridRobotHistoryData?.HoldingTradeInfo);

      setRemainHoldingInfo(fetchAutoGridRobotHistoryData?.RemainHoldingInfo);

      // setCurrentHoldingAfterSettlementPercent(
      //   Number(
      //     fetchAutoGridRobotHistoryData?.CurrentSettlementHoldingPercent?.split(
      //       "%"
      //     )[0]
      //   )
      // );

      setHoldingsChangeTrend(
        fetchAutoGridRobotHistoryData?.CurrentHoldingRanges
      );
      setSettlementRecords(
        fetchAutoGridRobotHistoryData?.AutoGridRobotHistories
      );

      setMonthList(
        monthData
          .slice(0, monthData.indexOf(moment().month() + 1) + 1)
          .map((item) => {
            return {
              Key: item,
              UnitValue: item,
              Value: item,
            };
          })
      );
    }

    if (fetchAutoGridRobotHistoryIsError) {
      ErrorMySwal({
        title:
          fetchAutoGridRobotHistoryError?.data?.ResultLanguages[
            getLanguage()
          ] || fetchAutoGridRobotHistoryError?.data?.Message,
      });
    }
  }, [fetchAutoGridRobotHistoryData, fetchAutoGridRobotHistoryIsError]);

  const [holdingsChangeTrendDay, setHoldingsChangeTrendDay] = useState(7);
  const holdingsChangeTrendDaysList = [7, 30];
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    setChartData(holdingsChangeTrend.slice(`-${holdingsChangeTrendDay}`));
  }, [holdingsChangeTrendDay, holdingsChangeTrend]);

  const tableHeader = [
    {
      id: 1,
      lang: <Lang datakey="trade_time" defaultText="Trade Time" />,
      value: "TradeTime",
    },
    {
      id: 2,
      lang: <Lang datakey="status" defaultText="Status" />,
      value: "PositionStatus",
    },
    {
      id: 3,
      lang: <Lang datakey="buy_price" defaultText="Buy Price" />,
      value: "BuyPrice",
    },
    {
      id: 4,
      lang: <Lang datakey="position_action" defaultText="Action" />,
      value: "Action",
    },
    {
      id: 5,
      lang: <Lang datakey="sell_price" defaultText="Sell Price" />,
      value: "SellPrice",
    },
    {
      id: 6,
      lang: <Lang datakey="profit" defaultText="Profit" />,
      value: "Profit",
    },
    // {
    //   id: 2,
    //   lang: (
    //     <Lang
    //       datakey="quantity_gains_and_losses"
    //       defaultText="Quantity Gains/Losses"
    //     />
    //   ),
    //   value: "quantityGainsAndLosses",
    // },
    // {
    //   id: 3,
    //   lang: <Lang datakey="growth" defaultText="Growth" />,
    //   value: "growth",
    // },
  ];

  return (
    <Wrapper>
      {fetchAutoGridRobotHistoryIsLoading && <Loading />}
      <Container>
        <Title>
          <Lang datakey="mining_coin_future" defaultText="COIN-FUTURE" />
        </Title>
        <ToolBar>
          {/* <TabWrapper>
            {historyTypeList.map((data) => (
              <TabContainer key={data.key}>
                <TabItem
                  $activeTab={data.key === historyType}
                  onClick={() => setHistoryType(data.key)}
                >
                  {data.lang}
                </TabItem>
              </TabContainer>
            ))}
          </TabWrapper> */}

          {/* -------- 幣別、月份 -------- */}
          <ToolBarContainer>
            <CurrencySelector>
              <SelectDataCenter
                title={<Lang datakey="currency" defaultText="Currency" />}
                dataList={currencyList}
                onChangeFun={setCurrency}
                nowValue={currency}
              />
            </CurrencySelector>

            <SettlementRecordsMonthSelector>
              <SelectDataCenter
                title={<Lang datakey="month" defaultText="Month" />}
                dataList={monthList}
                onChangeFun={setMonth}
                nowValue={month}
              />
            </SettlementRecordsMonthSelector>
          </ToolBarContainer>
          {/* -------- 幣別、月份 -------- */}
        </ToolBar>

        <Box>
          <CurrencySettingWrapper>
            <CurrentStatusWrapper>
              {/* 當前持有量 */}
              <CurrentHoldingsInfo>
                <KeyWrapper>
                  <Lang
                    datakey="mining_formula"
                    defaultText="Total = initial + future increase + spot increase"
                  />
                  {/* 總量 = 初始 + 合約增量 + 現貨增量 */}
                </KeyWrapper>
                <ValueWrapper>
                  {Number(initialAmount + profitAmount + spotAmount).toFixed(4)}
                  {" = "}
                  {Number(initialAmount)?.toFixed(4)}
                  {" + "}
                  {Number(profitAmount)?.toFixed(4)}
                  {" + "}
                  {Number(spotAmount)?.toFixed(4)}
                  {/* <Value $status={Number(currentHoldingPercent) >= 0}>
                    ({Number(currentHoldingPercent) >= 0 ? "+" : "-"}
                    {Number(currentHoldingPercent)?.toFixed(3)}
                    <ValueSymbol>%</ValueSymbol>)
                  </Value> */}
                </ValueWrapper>
              </CurrentHoldingsInfo>

              <CurrentHoldingsInfo>
                <KeyWrapper>
                  <Lang
                    datakey="mining_transaction_holdings"
                    defaultText="Transaction holdings(amount / avg entry /pnl)"
                  />
                  {/* 交易持倉(量/均價/損益) */}
                </KeyWrapper>
                <ValueWrapper>
                  {Number(holdingTradeInfo.HoldingAmount)?.toFixed(3)}
                  {" / "}
                  {Math.round(holdingTradeInfo.AvgPrice)}
                  {" / "}
                  <ProfitValue profit={Number(holdingTradeInfo.ProfitAmount)}>
                    {Number(holdingTradeInfo.ProfitAmount)?.toFixed(5)}
                  </ProfitValue>

                  {/* <Value $status={currentHoldingAfterSettlementPercent >= 0}>
                    (
                    {Number(currentHoldingAfterSettlementPercent) >= 0
                      ? "+"
                      : ""}
                    {Number(currentHoldingAfterSettlementPercent)?.toFixed(3)}
                    <ValueSymbol>%</ValueSymbol>)
                  </Value> */}
                </ValueWrapper>
              </CurrentHoldingsInfo>

              <CurrentHoldingsInfo>
                <KeyWrapper>
                  <Lang
                    datakey="mining_temporary_holdings"
                    defaultText="Temporary holdings(amount / avg entry /pnl)"
                  />
                  {/* 臨時持倉(量/均價/損益) */}
                </KeyWrapper>
                <ValueWrapper>
                  {Number(remainHoldingInfo.HoldingAmount)?.toFixed(3)}
                  {" / "}
                  {Math.round(remainHoldingInfo.AvgPrice)}
                  {" / "}
                  <ProfitValue profit={Number(remainHoldingInfo.ProfitAmount)}>
                    {Number(remainHoldingInfo.ProfitAmount)?.toFixed(5)}
                  </ProfitValue>
                  {/* <Value $status={currentHoldingAfterSettlementPercent >= 0}>
                    (
                    {Number(currentHoldingAfterSettlementPercent) >= 0
                      ? "+"
                      : ""}
                    {Number(currentHoldingAfterSettlementPercent)?.toFixed(3)}
                    <ValueSymbol>%</ValueSymbol>)
                  </Value> */}
                </ValueWrapper>
              </CurrentHoldingsInfo>
            </CurrentStatusWrapper>
          </CurrencySettingWrapper>

          <ChartWrapper>
            <ChartTitleWrapper>
              <DateSelector>
                {holdingsChangeTrendDaysList.map((day) => (
                  <DateItem
                    key={day}
                    onClick={() => setHoldingsChangeTrendDay(day)}
                    $active={holdingsChangeTrendDay === day}
                  >
                    {day}
                    <Lang datakey="d" defaultText="D" />
                  </DateItem>
                ))}
              </DateSelector>
              {/* <NextSettlementDate>
                <NextSettlementDateTitle>
                  <Lang
                    datakey="next_settlement_date"
                    defaultText="Next Settlement Date"
                  />
                </NextSettlementDateTitle>
                <Date>2024-05-17</Date>
              </NextSettlementDate> */}
            </ChartTitleWrapper>
            <ChartContainer>
              <Chart chartData={chartData} />
            </ChartContainer>
          </ChartWrapper>
        </Box>

        <SettlementRecordsWrapper>
          <SettlementRecordsTitleWrapper>
            <SettlementRecordsTitle>
              <Lang
                datakey="settlement_records"
                defaultText="Settlement Records"
              />
            </SettlementRecordsTitle>
          </SettlementRecordsTitleWrapper>

          {/* Future | spot 分類按鈕 */}
          <TabWrapper>
            {historyTypeList.map((data) => (
              <TabContainer key={data.key}>
                <TabItem
                  $activeTab={data.key === historyType}
                  onClick={() => tabClick(data.key)}
                >
                  {data.lang}
                </TabItem>
              </TabContainer>
            ))}
          </TabWrapper>
          {/* Future | spot 分類按鈕 */}

          {/* ----- table Settlement Records 區塊 ----- */}
          <SettlementRecordsContainer>
            {currentRecords.length > 0 ? (
              <TableEl>
                <TheadEl>
                  <TrEl>
                    {tableHeader.map((data, index) => (
                      <ThEl key={index}>{data.lang}</ThEl>
                    ))}
                  </TrEl>
                </TheadEl>
                <TbodyEl>
                  {currentRecords.map((data) => (
                    <TrEl key={data.orderId}>
                      {tableHeader.map((item, index) => (
                        <TdEl key={index}>
                          {(() => {
                            switch (item.value) {
                              case "Profit":
                                return (
                                  <div>
                                    {data.ProfitAmount == 0
                                      ? "N/A"
                                      : Number(data.ProfitAmount).toFixed(6)}
                                  </div>
                                );

                              case "SellPrice":
                                return (
                                  <div>
                                    {data[item.value] ?? "N/A"}
                                    <span
                                      style={{
                                        color:
                                          data.ProfitPercent.split("%")[0] >= 0
                                            ? "#1cac2a"
                                            : "#f44336",
                                      }}
                                    >
                                      {" "}
                                      {`(${Number(
                                        data.ProfitPercent.split("%")[0]
                                      ).toFixed(3)}%)`}
                                    </span>
                                  </div>
                                );

                              default:
                                return data[item.value];
                            }
                          })()}
                        </TdEl>
                      ))}
                    </TrEl>
                  ))}
                </TbodyEl>
              </TableEl>
            ) : (
              <Nodata>
                <Lang datakey="nodata" defaultText="No data available" />
              </Nodata>
            )}
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              changePage={changePage}
            />
          </SettlementRecordsContainer>
          {/* ----- table Settlement Records 區塊 ----- */}
        </SettlementRecordsWrapper>
      </Container>
    </Wrapper>
  );
};

export default MiningCoinFuture;
