import styled, { css } from "styled-components";
import { RWD_SM } from "../../../utils/rwd";
import { useEffect, useState } from "react";
import { useVerifyEmailMutation } from "../../../services/authApi";
import Progress from "./Progress";

import { handleParseLanguage } from "../../../utils";
import { SuccessMySwal } from "../../../global/MySwal";
import Loading from "../../../components/Loading/Loading";
import { getLanguage } from "../../../services/common";
import { useParams } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const Container = styled.div`
  width: clamp(280px, 40vw, 500px);
  flex: 1;
  display: flex;
  flex-direction: column;

  ${RWD_SM(css`
    width: 85vw;
  `)};
`;

const Title = styled.h2`
  font-size: clamp(22px, 1.822vw, 35px);
`;

const Info = styled.div`
  margin-bottom: 20px;
  font-size: clamp(14px, 0.833vw, 16px);
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputEl = styled.input`
  color: #fcfcfc;
  padding: 10px 10px;

  width: 100%;

  color: #fcfcfc;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */

  /* background: rgba(255, 255, 255, 0.1); */
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );

  &::placeholder {
    font-size: clamp(14px, 0.833vw, 16px);
  }
`;

const LabelEl = styled.label`
  /* font-size: 20px; */
`;

const FormEl = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  justify-content: space-between;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorMsg = styled.div`
  font-size: clamp(14px, 0.833vw, 15px);
  color: #f05d2f;
  height: 20px;
`;

const VerifyEmail = ({ account, step, stepCount, handleNextStep }) => {
  const { emailVerifyCode } = useParams();
  const [errorMsg, setErrorMsg] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const isValid = verifyCode && verifyCode.length === 6;

  const [_verifyEmail, { isSuccess, isError, error, isLoading }] =
    useVerifyEmailMutation();

  const handleSengVerifyCode = async (e) => {
    e.preventDefault();
    await _verifyEmail({
      body: {
        Account: account,
        VerifyCode: verifyCode,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      // 多國語言
      SuccessMySwal({
        title: handleParseLanguage("verification_successful"),
        showCancelButton: false,
        confirmButtonText: handleParseLanguage("confirm"),
      }).then((result) => {
        if (result.isConfirmed) {
          handleNextStep();
        }
      });
    }
    if (isError) {
      setErrorMsg(
        error?.data?.ResultLanguages?.[getLanguage()] || error?.data?.Message
      );
    }
  }, [isSuccess, isError, isLoading]);

  useEffect(() => {
    if (verifyCode) setErrorMsg("");
  }, [verifyCode]);

  useEffect(() => {
    if (emailVerifyCode) {
      setVerifyCode(emailVerifyCode);
    }
  }, [emailVerifyCode]);

  return (
    <>
      <Wrapper>
        {isLoading && <Loading />}
        <Container>
          <Title>{handleParseLanguage("register_mailverify_title")}</Title>
          <Info>{handleParseLanguage("register_mailverify_directions")}</Info>
          <FormEl>
            <FormContainer>
              <InputWrapper>
                <InputEl
                  placeholder={handleParseLanguage("verification_code")}
                  onChange={(e) => setVerifyCode(e.target.value)}
                  value={verifyCode}
                />
              </InputWrapper>
              <ErrorMsg>{errorMsg}</ErrorMsg>
            </FormContainer>
          </FormEl>
        </Container>
      </Wrapper>
      <Progress
        stepCount={stepCount}
        step={step}
        isValid={isValid}
        handleAction={handleSengVerifyCode}
      />
    </>
  );
};

export default VerifyEmail;
