import { useEffect, useState, Fragment } from "react";
import styled, { css } from "styled-components";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import {
  useGetMiningDetailMutation,
  useCancelWaitingPackageMutation,
} from "../../services/miningApi";
import { useSelector } from "react-redux";
import { selectSystem } from "../../slices/systemSlice";
import Icons from "../../global/icons";
import MiningLineChart from "./MiningLineChart";
import MiningHistory from "./MiningHistory";
import MiningTableRwd from "./MiningTableRwd";
import MiningCoinFuture from "./MiningCoinFuture";
import Lang from "../../global/Lang";
import { useNavigate, useLocation } from "react-router-dom";
import { handleMiningStatusColorType } from "../../utils/ButtonStyle";
import { LangString } from "../../utils";
import { SuccessMySwal, WarningMySwal, ErrorMySwal } from "../../global/MySwal";
import { getLanguage } from "../../services/common";

import BCH from "../../images/crypto/bch.png";
import BNB from "../../images/crypto/bnb.png";
import BTC from "../../images/crypto/btc.png";
import CAKE from "../../images/crypto/cake.png";
import ETH from "../../images/crypto/eth.png";
import IOTA from "../../images/crypto/iota.png";
import NEO from "../../images/crypto/neo.png";
import USDT from "../../images/crypto/usdt.png";
import EOS from "../../images/crypto/eos.png";
import ETC from "../../images/crypto/etc.png";

import { handleSortData } from "../../utils";
import JoyrideComponent from "../../global/JoyrideComponent";
import { useUpdateWatchingTutorialMutation } from "../../services/accountApi";

import Preview from "./components/Preview";

import { useOutletContext } from "react-router-dom";
import moment from "moment";
import { BsFillArrowUpCircleFill } from "react-icons/bs";

import { useUpdateMiningInfoMutation } from "../../services/miningApi";

import Loading from "../../components/Loading/Loading";

const Wrapper = styled.div`
  /* border: 1px solid blue; */
`;

const Title = styled.div`
  font-size: min(max(1.145vw, 20px), 22px);
  padding-bottom: 24px;
  color: #fcfcfc;

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 18px), 20px);
  `)};
`;

const TableWrapper = styled.div`
  background-color: #22254a;
  padding: 1.5625vw 2.0833vw;
  border-radius: 0 8px 8px 8px;

  ${RWD_MD(css`
    padding: 0;
    background-color: transparent;
  `)};
`;

const TableEl = styled.div`
  width: 100%;
  height: 100%;
  white-space: nowrap;
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;

  ${RWD_LG(css`
    overflow: hidden;
  `)};
`;

const Thead = styled.div`
  font-size: min(max(0.9375vw, 14px), 18px);
  color: #54587b;
  display: flex;
  padding-bottom: 1.5625vw;

  ${RWD_MD(css`
    display: none;
  `)};
`;

const Th = styled.div`
  font-weight: 400;
  display: flex;
  align-items: center;
`;

const Tr = styled.div`
  border-bottom: 1px solid #3a3d5a;
  display: flex;
  position: relative;

  background-color: ${(props) =>
    props.status ? "rgba(255, 255, 255, 0.05)" : ""};

  ${RWD_MD(css`
    align-items: center;
    flex-direction: column;
  `)};
`;

const Td = styled.div`
  color: #fcfcfc;
  padding: 1.041vw 0px;
  font-size: min(max(0.833vw, 14px), 16px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  overflow: ${(props) =>
    props.type === "StatusAction" ? "inherit" : "hidden"};

  ${RWD_MD(css`
    width: 60%;
  `)};
`;

const TextWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Tbody = styled.div`
  height: 100%;
  width: 100%;
  /* border: 1px solid rebeccapurple; */

  /* ${RWD_LG(css`
    display: none;
  `)}; */
`;

const TbodyContainer = styled.div`
  width: 100%;

  ${RWD_MD(css`
    display: none;
  `)};
`;

const DetailContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-family: Montserrat;
  height: 100%;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  padding: 3vw 1.5625vw 3vw 1.5625vw;
  ${RWD_MD(css`
    padding: 25px 10px;
  `)};
`;

const DetailContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 92%;
  gap: 20px;
`;

const DetailContentContainer = styled.div`
  display: flex;

  gap: 20px;
`;

const ProfitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  gap: 20px;
`;

const ProfitTitle = styled.div`
  color: #0aecd1;
  font-size: min(max(0.833vw, 14px), 16px);
  padding-bottom: 12px;

  ${RWD_LG(css`
    align-self: flex-start;
    padding-bottom: 0;
  `)};
`;

const Profit = styled.div`
  height: 100%;
  background-color: #3a3d5a;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ProfitValueWrapper = styled.div`
  color: #fcfcfc;
  font-size: 1.8vw;
  font-weight: bold;
  display: flex;

  justify-content: space-between;
  align-items: flex-end;
`;

const ProfitAmount = styled.div`
  padding-left: 5px;
`;

const ProfitAmountWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const ProfitPercentageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1cac2a;
`;

const ProfitPercentageValue = styled.div`
  padding-right: 5px;
`;

const ProfitPercentageValueContent = styled.div`
  display: flex;
  align-items: baseline;
`;

const ArrowUpIcon = styled.div`
  font-size: min(max(0.9375vw, 16px), 18px);
`;

const ProfitSymbol = styled.div`
  font-size: min(max(1.145vw, 20px), 22px);

  ${RWD_SM(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const ReturnTypeWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  /* flex-direction: column; */
  width: 100%;
`;

const ReturnTypeDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

const ReturnTypeDate = styled.div`
  /* border: 1px solid yellow; */
`;

const ReturnTypeItemContainer = styled.div`
  display: flex;
`;

const ReturnTypeItem = styled.div`
  padding-right: 5px;
  /* text-overflow: ellipsis; */
`;

const TabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TabContainer = styled.div`
  display: flex;
  width: 80%;

  ${RWD_SM(css`
    width: 90%;
  `)};
`;

const TabItem = styled.div`
  width: 110px;
  background-color: ${(props) => (props.activeTab ? "#22254a" : "")};
  text-align: center;
  padding: 8px 0px;
  border-radius: 8px 8px 0 0;
  color: ${(props) => (props.activeTab ? "#fcfcfc" : "#54587b")};
  margin-right: 5px;
  cursor: pointer;
  font-size: min(max(0.833vw, 14px), 16px);
  text-transform: capitalize;

  border-bottom: ${(props) =>
    props.activeTab ? "2px solid #0aecd1" : "1px solid trasparent"};

  ${RWD_SM(css`
    width: 33%;
  `)};
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${(props) => (props.status ? "scaleY(-1)" : "scaleY(1)")};
  cursor: pointer;
  transition: all 0.3s;
  svg {
    & path {
      stroke: #54587b;
    }
  }
`;

const SortContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${(props) => (props.status ? "scaleY(-1)" : "scaleY(1)")};
  transition: all 0.3s;
  padding-left: 10px;

  svg {
    ${RWD_SM(css`
      width: 10px;
      height: 10px;
    `)};

    & path {
      fill: #54587b;
    }
  }
`;

const NoDataWrapper = styled.div`
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #54587b;
  font-size: min(max(1.145vw, 20px), 22px);
`;

const NameWrapper = styled.div`
  /* position: relative; */
  width: 95%;

  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  display: inline-flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  :hover {
    & path {
      fill: #0aecd1;
    }
  }

  & path {
    fill: #fcfcfc;
  }
`;

const TargetWrapper = styled.div`
  width: 30px;

  ${RWD_LG(css`
    width: 25px;
  `)};
`;

const DetailButtonWrapper = styled.div`
  /* position: absolute; */
  /* right: 1.5625vw;
  bottom: 1.0416vw; */

  position: absolute;
  right: 1.5625vw;
  bottom: 1.0416vw;
  /* margin-left: auto; */
  color: #fcfcfc;
  font-size: min(max(0.833vw, 14px), 16px);
  cursor: pointer;

  ${RWD_MD(css`
    right: 10px;
    bottom: 10px;
    font-size: 12px;
  `)};
`;

const MiningTextWrapper = styled.div`
  color: ${(props) => (props.type === "true" ? "#1CAC2A" : "#F05D2F")};
`;

const StatusButton = styled.div`
  width: 100%;
  width: min(max(4.5833vw, 75px), 88px);
  padding: 0.3125vw 0.78125vw;
  border-radius: 100px;
  background-color: ${({ type }) => handleMiningStatusColorType(type).bg};
  display: flex;
  align-items: center;
  justify-content: center;

  ${RWD_MD(css`
    padding: 5px 10px;
  `)};
`;

const RwdSortHeader = styled.div`
  display: flex;
  font-size: min(max(0.9375vw, 12px), 18px);
  color: #54587b;
  padding: 5px;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  width: 33%;
  justify-content: space-between;

  :last-child {
    border-right: 0px;
  }
`;

const RwdSortHeaderWrapper = styled.div`
  display: none;
  background-color: #22254a;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  ${RWD_MD(css`
    display: flex;
  `)};
`;

const HistoryWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 5px;

  :hover {
    path {
      fill: #0aecd1;
    }
  }
`;

const HistoryIcon = styled.div`
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & path {
    fill: #fcfcfc;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const HistoryText = styled.div`
  color: #fcfcfc;
  padding-left: 10px;

  ${RWD_SM(css`
    display: none;
  `)};
`;

const TransferButtonWrapper = styled.div`
  position: absolute;
  padding: 0.52vw 1.3vw;
  border-radius: 60px;
  /* width: min(max(7.0833vw, 80px), 100px); */
  background: linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%);
  right: 1vw;
  top: 1vw;
  text-align: center;
  color: #fcfcfc;
  font-size: min(max(0.833vw, 14px), 16px);
  cursor: pointer;

  ${RWD_LG(css`
    /* right: 10px;
    bottom: 10px; */
    padding: 5px;
    top: 5px;
    font-size: 12px;
  `)};
`;

const WPButton = styled.div`
  position: absolute;
  top: 5vw;
  right: 1vw;
  border-radius: 60px;
  padding: 0.52vw 1.3vw;
  background: linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%);
  color: #fcfcfc;
  cursor: pointer;

  ${RWD_LG(css`
    padding: 5px;
    font-size: 12px;
  `)};
`;

const MiningTable = ({
  miningData,
  setModalOpen,
  setMiningId,
  setModalType,
  transferUsdt,
  setReportHistoryIsOpen,
  reportTxidIsSuccess,
  handleEdit
}) => {
  const navigate = useNavigate();
  const { addMiningStatus } = useOutletContext();
  const lang = useSelector(selectSystem).language;
  const [language, setLanguage] = useState("");
  useEffect(() => {
    setLanguage(lang);
  }, [lang]);


  useEffect(() => {
    if (addMiningStatus[0]) {
      setActiveTab("waiting");
    }
  }, [addMiningStatus]);

  useEffect(() => {
    // 如果Txid回報成功 導回running tab
    if (reportTxidIsSuccess) {
      setActiveTab("running");
    }
  }, [reportTxidIsSuccess]);

  const [activeTab, setActiveTab] = useState("running");
  const tabStatus = [
    {
      key: "running",
      value: <Lang datakey="running" defaultText="Running" />,
    },
    {
      key: "waiting",
      value: <Lang datakey="waiting" defaultText="Waiting" />,
    },
    {
      key: "over",
      value: <Lang datakey="over" defaultText="Over" />,
    },
  ];
  const [tableData, setTableData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartDataInit, setChartDataInit] = useState([]);
  const [chartLabel, serChartLabel] = useState([]);
  const [returnType, setReturnType] = useState([]);
  const [previewData, setPreviewData] = useState([]);

  const [historyModalIsOpen, setHistoryModalIsOpen] = useState(false);
  const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false);

  const cryptologos = [
    { Src: BCH, Target: "BCH" },
    { Src: BNB, Target: "BNB" },
    { Src: BTC, Target: "BTC" },
    { Src: CAKE, Target: "CAKE" },
    { Src: ETH, Target: "ETH" },
    { Src: IOTA, Target: "IOTA" },
    { Src: NEO, Target: "NEO" },
    { Src: USDT, Target: "USDT" },
    { Src: EOS, Target: "EOS" },
    { Src: ETC, Target: "ETC" },
  ];

  const [
    updateMining,
    {
      data: updateMiningData,
      isSuccess: updateMiningIsSuccess,
      isError: updateMiningIsError,
      error: updateMiningError,
      isLoading: updateMiningIsLoading,
    },
  ] = useUpdateMiningInfoMutation();

  const updateMiningHandler = async (id) => {
    try {
      const response = await updateMining({
        body: {
          GroupId: id,
          ReturnType: 2,
        }
      })

      if (response.data.ResultCode === 0) {
        const zhTitle = "更新成功";
        const enTitle = "Update Success";
        const confirmText = LangString("confirm", "Confirm");
        SuccessMySwal({
          title: language === "En" ? enTitle : zhTitle,
          showCancelButton: false,
          confirmButtonText: confirmText,
        });
      } else {
        const zhTitle = "更新失敗！";
        const enTitle = "Update fail";
        ErrorMySwal({
          title: language === "En" ? enTitle : zhTitle,
        });
      }

    } catch (error) {
      ErrorMySwal({
        title: "update fail",
      })
    }
  }

  const WPbuttonHandler = (id) => {
    const zhTitle = "警告";
    const enTitle = "Warning";
    const zhText =
      "是否確認執行增量器(CI)的動態結算 ?確認執行後，將無法再做任何修改，系統將會直接進行結算，並於最近的一次轉帳日(每月5號/20號)，將所有的Token返還到您的地址。";
    const enText =
      "Are you sure you want to proceed with the dynamic close of the Coin Increment (CI)?Once confirmed, no further changes can be made. The system will proceed directly with the settlement, and all Tokens will be returned to your address on the nearest transfer date (5th or 20th of each month).";

    const confirmText = LangString("confirm", "Confirm");
    const cancelText = LangString("cancel", "Cancel");

    WarningMySwal({
      title: language === "En" ? enTitle : zhTitle,
      text: language === "En" ? enText : zhText,
      cancelButtonText: cancelText,
      confirmButtonText: confirmText,
      showCancelButton: true,
      onConfirm: () => {
        updateMiningHandler(id);
      },
      onCancel: () => {
        return;
      }
    })
  }


  const [joyrideIsRun, setJoyrideIsRun] = useState(false);
  const { search } = useLocation();

  const [_updateWatchingTutorial, { data, isSuccess, isError, error }] =
    useUpdateWatchingTutorialMutation();

  const [
    _cancelWaitingPackage,
    {
      data: cancelWaitingPackageData,
      isSuccess: cancelWaitingPackageIsSuccess,
      isError: cancelWaitingPackageIsError,
      error: cancelWaitingPackageError,
    },
  ] = useCancelWaitingPackageMutation();

  useEffect(() => {
    if (isSuccess) {
      // 多國語言
      // const title = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");
      const titleText = LangString("tutorial_complete", "Tutorial complete!");
      SuccessMySwal({
        title: titleText,
        showCancelButton: false,
        confirmButtonText: confirmText,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/v2/mining`);
        }
      });
    }
    if (isError) {
      ErrorMySwal({
        title:
          error?.data?.ResultLanguages[getLanguage()] || error?.data?.Message,
      });
    }
  }, [data, isError]);

  useEffect(() => {
    if (cancelWaitingPackageIsSuccess) {
      // 取消Waiting Package
      // 多國語言
      const title = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });
    }

    if (cancelWaitingPackageIsError) {
      ErrorMySwal({
        title:
          cancelWaitingPackageError?.data?.ResultLanguages[getLanguage()] ||
          cancelWaitingPackageError?.data?.Message,
      });
    }
  }, [cancelWaitingPackageData, cancelWaitingPackageIsError]);

  const handleJoyrideCallback = () => {
    _updateWatchingTutorial({
      body: {
        TutorialKey: "Mining",
      },
    });
  };

  const joyrideState = [
    {
      target: ".createMining_waitingTable",
      content: (
        <Lang
          datakey="mining_tutorial_6-1"
          defaultText="You can click the edit button of the TXID to view the AVA system's receiving address and to enter the TXID after the transfer is complete. Once completed, this Package will be activated."
        />
      ),
      placement: "right",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: true,
      finished: true,
      // function: handleJoyrideCallback,
    },
  ];

  useEffect(() => {
    const query = new URLSearchParams(search);
    const tutorial = query.get("tutorial");
    if (tutorial === "mining") {
      setActiveTab("waiting");
      setJoyrideIsRun(true);
    }
  }, [search]);

  const [tableHeader, setTableHeader] = useState([
    {
      id: 1,
      defaultText: "CreateTime",
      datakey: "mining_table_createtime",
      value: "CreateTime",
      width: "8%",
      position: "center",
      sort: true,
      ascending: false,
    },
    {
      id: 2,
      defaultText: "Target",
      datakey: "mining_table_target",
      value: "Target",
      width: "8%",
      position: "center",
    },
    {
      id: 3,
      defaultText: "Name",
      datakey: "mining_table_name",
      value: "Name",
      width: "25%",
      sort: true,
      ascending: false,
    },
    {
      id: 4,
      defaultText: "Mining",
      datakey: "mining_table_mining",
      value: "mining",
      width: "8%",
    },
    {
      id: 5,
      defaultText: "CurrentVolume/Deposit",
      datakey: "mining_table_currentdeposit",
      value: "deposit",
      width: "15%",
    },
    {
      id: 6,
      defaultText: "WithdrawalVolume",
      datakey: "mining_table_withdrawalvolume",
      value: "WithdrawalVolume",
      width: "15%",
    },
    {
      id: 7,
      defaultText: "PeriodEndDate",
      datakey: "mining_table_periodenddate",
      value: "PeriodEndDate",
      width: "16%",
      sort: true,
      ascending: false,
    },
    {
      id: 8,
      defaultText: "TXID",
      datakey: "Txid",
      value: "Txid",
      width: "16%",
    },
    {
      id: 9,
      defaultText: "Action",
      datakey: "mining_table_action",
      value: "Action",
      width: "5%",
      position: "center",
    },
  ]);

  const [
    _getMiningDetail,
    {
      data: miningDetailResponse,
      isSuccess: miningDetailResponseIsSuccess,
      isError: miningDetailResponseIsError,
      error: miningDetailResponseError,
    },
  ] = useGetMiningDetailMutation();

  useEffect(() => {
    if (!miningData) return;
    setTableData(() =>
      // 狀態不是 waiting running over 的話 都改成 waiting
      // 在 waiting 的 tab 中顯示
      miningData.map((item) => ({
        ...item,
        Status:
          item.Status !== "waiting" &&
          item.Status !== "running" &&
          item.Status !== "over"
            ? "waiting"
            : item.Status,
        isOpen: true,
        buttonIsShow: false,
      }))
    );
  }, [miningData]);

  useEffect(() => {
    if (miningDetailResponseIsSuccess) {
      const formatDate = (dateString) => {
        return dateString.replace(/\//g, "-");
      };
      setChartData(miningDetailResponse?.MiningPeriods);
      setChartDataInit(miningDetailResponse?.FirstPeriodAmounts);
      serChartLabel(
        miningDetailResponse?.Labels?.map((item) => formatDate(item))
      );
      setReturnType(miningDetailResponse?.ReturnTypes);
    }

    if (miningDetailResponseIsError) {
      console.log(miningDetailResponseError?.data.Message);
    }
  }, [miningDetailResponse, miningDetailResponseIsError]);

  const transferMining = (data) => {
    // HistoryProfit + (ProfitTest  * 目前價值)
    return data
      .map(
        (item) =>
          item.HistoryProfit + transferUsdt(item.Target, item.ProfitTest)
        // transferUsdt(item.Target, item.Profit)
      )
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };

  const transferDeposit = (data) => {
    return data
      .map((item) => transferUsdt(item.Target, item.CurrentVolume))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };

  const handleButtonShow = (id) => {
    setTableData(
      tableData.filter((data) => {
        data.buttonIsShow = data.GroupId === id ? !data.buttonIsShow : false;
        return data;
      })
    );
  };

  const handleDataOpen = (id, packageId) => {
    _getMiningDetail({
      body: {
        GroupId: id,
      },
    });

    setTableData(
      tableData.map((data) => {
        return {
          ...data,
          IsOpen: data.GroupId === id ? !data.IsOpen : false,
        };
      })
    );
  };

  const handleEditName = (id) => {
    setModalType("name");
    setMiningId(id);
    setModalOpen(true);
  };

  const handleChangeReturnType = (id) => {
    setModalType("returntype");
    setMiningId(id);
    setModalOpen(true);
  };

  const handleTxid = (id) => {
    setModalType("txid");
    setMiningId(id);
    setModalOpen(true);
  };

  const handleSort = (type, ascending, sortable) => {
    if (sortable) {
      const sortedData = handleSortData(type, ascending, miningData);
      setTableData(() =>
        sortedData.map((item) => ({
          ...item,
          isOpen: false,
        }))
      );
      setTableHeader(
        tableHeader.map((data) => {
          if (data.value === type) {
            return {
              ...data,
              ascending: !data.ascending,
            };
          } else {
            return data;
          }
        })
      );
    } else {
      return;
    }
  };

  const handleReportHistory = () => {
    setReportHistoryIsOpen(true);
  };

  const handleSelectTransferPackage = (id) => {
    navigate(`transferpackage/${id}`);
  };

  const handlePreviewTransferPackage = (id) => {
    setPreviewModalIsOpen(true);
    setPreviewData(miningData.filter((data) => data.GroupId === id));
  };

  // 刪除Waiting Package
  const handleCancelWaitingPackage = (id) => {
    // 多國語言

    const chTitle = "是否取消目前待啟動的Package";
    const enTitle = "Do you want to cancel the currently pending Package?";

    const confirmText = LangString("confirm", "Confirm");
    const cancelText = LangString("cancel", "Cancel");

    WarningMySwal({
      title: language === "En" ? enTitle : chTitle,
      showCancelButton: true,
      cancelButtonText: cancelText,
      confirmButtonText: confirmText,
    }).then((result) => {
      if (result.isConfirmed) {
        _cancelWaitingPackage({
          body: {
            GroupId: id,
          },
        });
      }
    });
  };

  const calculator = (arr) => {
    return arr.map((data, index, array) => {
      const subArray = array.slice(0, index + 1);
      const sum = subArray.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );

      return sum;
    });
  };

  // 累積獲利（金額）
  const AccumulatedProfit = (profitArray) => {
    // 先將每筆獲利轉換成USDT後加總
    // 陣列最後一個值即為累積獲利
    const sum = profitArray.map((item) => {
      return {
        data: calculator(
          item.Profits.map((profit) => transferUsdt(item.Target, profit))
        )[chartLabel.length - 1],
        target: item.Target,
      };
    });

    return sum;
  };

  // 累積獲利（百分比）
  const AccumulatedProfitPercentage = (profitArray) => {
    // 先將每筆數值乘以100後加總
    // 陣列最後一個值即為累積獲利
    const sum = profitArray.map((item) => {
      return {
        data: calculator(item.ProfitPercent.map((profit) => profit * 100))[
          chartLabel.length - 1
        ],
        target: item.Target,
      };
    });

    return sum;
  };

  // 三個月累積獲利（金額）
  const ThreeMonthAgoAccumulatedProfit = (profitArray) => {
    const sum = profitArray.map((item) => {
      return {
        data: calculator(
          item.Profits.map((profit, index) => {
            const today = moment(); // 獲取當前日期
            const date = moment(chartLabel[index]); // 獲取資料日期
            const diffDays = today.diff(date, "days"); // 獲取兩個日期相差的天數
            // 相差天數小於等於90天，則將獲利轉換成USDT後加總
            // 陣列最後一個值即為累積獲利
            if (diffDays <= 90) {
              return transferUsdt(item.Target, profit);
            } else {
              return 0;
            }
          })
        )[chartLabel.length - 1],
        target: item.Target,
      };
    });
    return sum;
  };

  // 三個月累積獲利（百分比）
  const ThreeMonthAgoAccumulatedProfitPercentage = (profitArray) => {
    const sum = profitArray.map((item) => {
      return {
        data: calculator(
          item.ProfitPercent.map((profit, index) => {
            const today = moment(); // 獲取當前日期
            const date = moment(chartLabel[index]); // 獲取資料日期
            const diffDays = today.diff(date, "days"); // 獲取兩個日期相差的天數
            // 相差天數小於等於90天，則將數值乘以100後加總
            // 陣列最後一個值即為累積獲利
            if (diffDays <= 90) {
              return profit * 100;
            } else {
              return 0;
            }
          })
        )[chartLabel.length - 1],
        target: item.Target,
      };
    });
    return sum;
  };

  return (
    <Wrapper className="createMining_waitingTable">
      <JoyrideComponent
        joyrideState={joyrideState}
        run={joyrideIsRun}
        joyrideType={"mining"}
      />

      {updateMiningIsLoading && <Loading />}


      <Title>
        <Lang datakey="summary" defaultText="Summary" />
      </Title>

      <TabWrapper>
        <TabContainer>
          {tabStatus.map((data) => (
            <TabItem
              key={data.key}
              activeTab={activeTab === data.key}
              onClick={() => setActiveTab(data.key)}
            >
              {data.value}
            </TabItem>
          ))}
        </TabContainer>
        <HistoryWrapper onClick={handleReportHistory}>
          <HistoryIcon>
            <Icons.History />
          </HistoryIcon>
          <HistoryText>
            <Lang datakey="return_history" defaultText="Return History" />
          </HistoryText>
        </HistoryWrapper>
      </TabWrapper>

      {tableData.filter((mining) => mining.Status === activeTab).length > 0 && (
        <RwdSortHeaderWrapper>
          {tableHeader
            .filter(
              (data) =>
                data.value === "PeriodEndDate" ||
                data.value === "Name" ||
                data.value === "CreateTime"
            )
            .map((item) => (
              <RwdSortHeader
                key={item.value}
                onClick={() => {
                  handleSort(item.value, !item.ascending, item.sort);
                }}
              >
                <Lang datakey={item.datakey} defaultText={item.defaultText} />
                {item.sort && (
                  <SortContainer status={item.ascending}>
                    <Icons.Sort />
                  </SortContainer>
                )}
              </RwdSortHeader>
            ))}
        </RwdSortHeaderWrapper>
      )}

      <TableWrapper>
        {tableData.filter((mining) => mining.Status === activeTab).length >
        0 ? (
          <TableEl>
            <Thead>
              {activeTab === "waiting"
                ? tableHeader
                    .filter((item) => item.value !== "PeriodEndDate")
                    .map((data) => (
                      <Th
                        key={data.id}
                        style={{
                          width: `${data.width}`,
                          justifyContent: `${data.position}`,
                          cursor: data.sort ? "pointer" : "default",
                        }}
                        onClick={() => {
                          handleSort(data.value, !data.ascending, data.sort);
                        }}
                      >
                        <Lang
                          datakey={data.datakey}
                          defaultText={data.defaultText}
                        />
                        {data.sort && (
                          <SortContainer status={data.ascending}>
                            <Icons.Sort />
                          </SortContainer>
                        )}
                      </Th>
                    ))
                : tableHeader
                    .filter((item) => item.value !== "Txid")
                    .map((data) => (
                      <Th
                        key={data.id}
                        style={{
                          width: `${data.width}`,
                          justifyContent: `${data.position}`,
                          cursor: data.sort ? "pointer" : "default",
                        }}
                        onClick={() => {
                          handleSort(data.value, !data.ascending, data.sort);
                        }}
                      >
                        <Lang
                          datakey={data.datakey}
                          defaultText={data.defaultText}
                        />
                        {data.sort && (
                          <SortContainer status={data.ascending}>
                            <Icons.Sort />
                          </SortContainer>
                        )}
                      </Th>
                    ))}
            </Thead>
            <Tbody>
              {tableData
                .filter((mining) => mining.Status === activeTab)
                .map((data, index) => (
                  <Fragment key={index}>
                    <TbodyContainer>
                      <Tr status={data.IsWaitingTransfer}>
                        {activeTab === "waiting"
                          ? tableHeader
                              .filter((data) => data.value !== "PeriodEndDate")
                              .map((item) => (
                                <Td
                                  key={item.id}
                                  style={{
                                    width: `${item.width}`,
                                    justifyContent: `${item.position}`,
                                  }}
                                >
                                  {(() => {
                                    switch (item.value?.toLowerCase()) {
                                      // (profit * 匯率)後加總
                                      // HistoryProfit + (ProfitTest  * 目前價值)
                                      case "mining":
                                        return (
                                          <MiningTextWrapper
                                            type={
                                              transferMining(data.PeriodItems) >
                                              0
                                                ? "true"
                                                : "false"
                                            }
                                          >
                                            {transferMining(
                                              data.PeriodItems
                                            ).toFixed(2)}
                                          </MiningTextWrapper>
                                        );
                                      // （currentvolume * 匯率後加總） / deposit
                                      case "deposit":
                                        return (
                                          <TextWrapper>
                                            {
                                              <Lang
                                                datakey="mining_getPrice"
                                                defaultText="Getting market price"
                                              />
                                            }
                                          </TextWrapper>
                                        );

                                      case "withdrawalvolume":
                                        return (
                                          <TextWrapper>
                                            {transferUsdt(
                                              data.Target,
                                              data.WithdrawalVolume
                                            ).toFixed(2)}
                                          </TextWrapper>
                                        );

                                      case "status":
                                        return (
                                          <StatusButton type={data[item.value]}>
                                            {data[item.value]}
                                          </StatusButton>
                                        );

                                      case "txid":
                                        return (
                                          <TextWrapper>
                                            <IconWrapper
                                              onClick={() =>
                                                handleTxid(data.GroupId)
                                              }
                                            >
                                              <Icons.Edit />
                                            </IconWrapper>
                                          </TextWrapper>
                                        );

                                      case "target":
                                        return (
                                          <TargetWrapper>
                                            <img
                                              src={
                                                cryptologos.filter(
                                                  (logo) =>
                                                    logo.Target ===
                                                    data[item.value]
                                                )[0]?.Src
                                              }
                                            />
                                          </TargetWrapper>
                                        );

                                      case "name":
                                        return (
                                          <NameWrapper
                                            data-tooltip-id={`package-name-${data.GroupId}`}
                                          >
                                            <TextWrapper>
                                              {data.Name} ({data.PackageName})
                                            </TextWrapper>

                                            <IconWrapper
                                              onClick={() =>
                                                handleEditName(data.GroupId)
                                              }
                                            >
                                              <Icons.Edit />
                                            </IconWrapper>
                                            <Tooltip
                                              id={`package-name-${data.GroupId}`}
                                              place="bottom-start"
                                              content={`${data.Name} (${data.PackageName})`}
                                              style={{
                                                backgroundColor: "#151944",
                                                color: "#fcfcfc",
                                              }}
                                            />
                                          </NameWrapper>
                                        );

                                      case "action":
                                        return (
                                          <ActionContainer
                                            status={data.IsOpen}
                                            onClick={() =>
                                              data.Status === "waiting"
                                                ? handleCancelWaitingPackage(
                                                    data.GroupId
                                                  )
                                                : handleDataOpen(
                                                    data.GroupId,
                                                    data.packageId
                                                  )
                                            }
                                          >
                                            {data.Status === "waiting" ? (
                                              <Icons.Close />
                                            ) : (
                                              <Icons.Arrow />
                                            )}
                                          </ActionContainer>
                                        );

                                      case "createtime":
                                        return (
                                          <Fragment>
                                            <TextWrapper
                                              data-tooltip-id={`createtime-${data.GroupId}`}
                                            >
                                              {data[item.value]}
                                            </TextWrapper>
                                            <Tooltip
                                              id={`createtime-${data.GroupId}`}
                                              place="bottom-start"
                                              content={data[item.value]}
                                              style={{
                                                backgroundColor: "#151944",
                                                color: "#fcfcfc",
                                              }}
                                            />
                                          </Fragment>
                                        );

                                      default:
                                        return (
                                          <TextWrapper>
                                            {data[item.value]}
                                          </TextWrapper>
                                        );
                                    }
                                  })()}
                                </Td>
                              ))
                          : tableHeader
                              .filter((data) => data.value !== "Txid")
                              .map((item) => (
                                <Td
                                  key={item.id}
                                  style={{
                                    width: `${item.width}`,
                                    justifyContent: `${item.position}`,
                                  }}
                                >
                                  {(() => {
                                    switch (item.value?.toLowerCase()) {
                                      // profit * 匯率後加總
                                      case "mining":
                                        return (
                                          <MiningTextWrapper
                                            type={
                                              transferMining(data.PeriodItems) >
                                              0
                                                ? "true"
                                                : "false"
                                            }
                                          >
                                            {transferMining(
                                              data.PeriodItems
                                            ).toFixed(2)}
                                          </MiningTextWrapper>
                                        );
                                      // （currentvolume * 匯率後加總） / deposit
                                      case "deposit":
                                        return (
                                          // TotalCurrentPrice為0時顯示取得價格中
                                          // 前端計算為0時顯示後端計算的TotalCurrentPrice
                                          <TextWrapper>
                                            {data.Status === "over" ? (
                                              `NaN / ${data.Deposit}`
                                            ) : data.TotalCurrentPrice == 0 ? (
                                              <Lang
                                                datakey="mining_getPrice"
                                                defaultText="Getting market price"
                                              />
                                            ) : transferDeposit(
                                                data.PeriodItems
                                              ) == 0 ? (
                                              data.TotalCurrentPrice
                                            ) : (
                                              `${transferDeposit(
                                                data.PeriodItems
                                              ).toFixed(2)} / ${data.Deposit}`
                                            )}
                                          </TextWrapper>
                                        );
                                      case "periodenddate":
                                        return (
                                          <ReturnTypeWrapper>
                                            <ReturnTypeDateWrapper
                                              data-tooltip-id={`periodenddate-${data.GroupId}`}
                                            >
                                              <ReturnTypeDate>
                                                {data.PeriodEndDate}
                                              </ReturnTypeDate>
                                              <ReturnTypeItemContainer>
                                                <ReturnTypeItem>
                                                  {(() => {
                                                    switch (data.ReturnType) {
                                                      case "no":
                                                        return "CP";
                                                      case "all":
                                                        return "WP";
                                                      case "interest":
                                                        return "WMT";
                                                      case "avapoint":
                                                        return "MAP";
                                                    }
                                                  })()}
                                                </ReturnTypeItem>
                                                <TextWrapper>
                                                  {data.SubDays
                                                    ? data.SubDays.toLowerCase() ===
                                                      "over"
                                                      ? `(Locked)`
                                                      : data.SubDays == 0
                                                      ? `(1 day left)`
                                                      : `(${data.SubDays} days left)`
                                                    : "-"}
                                                </TextWrapper>
                                              </ReturnTypeItemContainer>
                                            </ReturnTypeDateWrapper>

                                            {data.Status === "running" &&
                                              data.SubDays.toLowerCase() !==
                                                "over" && (
                                                <IconWrapper
                                                  onClick={() =>
                                                    handleChangeReturnType(
                                                      data.GroupId
                                                    )
                                                  }
                                                >
                                                  <Icons.Edit />
                                                </IconWrapper>
                                              )}
                                            <Tooltip
                                              id={`periodenddate-${data.GroupId}`}
                                              place="bottom"
                                              content={
                                                data.SubDays ? (
                                                  data.SubDays.toLowerCase() ===
                                                  "over" ? (
                                                    <Lang
                                                      datakey="mining_locked_tip"
                                                      defaultText="Due to the upcoming settlement time, the Return Type cannot be changed temporarily."
                                                    />
                                                  ) : data.SubDays == 0 ? (
                                                    `(1 day left)`
                                                  ) : (
                                                    `(${data.SubDays} days left)`
                                                  )
                                                ) : (
                                                  "-"
                                                )
                                              }
                                              style={{
                                                backgroundColor: "#151944",
                                                color: "#fcfcfc",
                                              }}
                                            />
                                          </ReturnTypeWrapper>
                                        );

                                      case "withdrawalvolume":
                                        return (
                                          <TextWrapper>
                                            {transferUsdt(
                                              data.Target,
                                              data.WithdrawalVolume
                                            ).toFixed(2)}
                                          </TextWrapper>
                                        );

                                      case "status":
                                        return (
                                          <StatusButton type={data[item.value]}>
                                            {data[item.value]}
                                          </StatusButton>
                                        );

                                      case "target":
                                        return (
                                          <TargetWrapper>
                                            <img
                                              src={
                                                cryptologos.filter(
                                                  (logo) =>
                                                    logo.Target ===
                                                    data[item.value]
                                                )[0]?.Src
                                              }
                                            />
                                          </TargetWrapper>
                                        );

                                      case "name":
                                        return (
                                          <NameWrapper
                                            data-tooltip-id={`package-name-${data.GroupId}`}
                                          >
                                            <TextWrapper>
                                              {data.Name} ({data.PackageName})
                                            </TextWrapper>

                                            <IconWrapper
                                              onClick={() =>
                                                handleEditName(data.GroupId)
                                              }
                                            >
                                              <Icons.Edit />
                                            </IconWrapper>
                                            <Tooltip
                                              id={`package-name-${data.GroupId}`}
                                              place="bottom-start"
                                              content={`${data.Name} (${data.PackageName})`}
                                              style={{
                                                backgroundColor: "#151944",
                                                color: "#fcfcfc",
                                              }}
                                            />
                                          </NameWrapper>
                                        );

                                      case "action":
                                        return (
                                          <ActionContainer
                                            status={data.IsOpen}
                                            onClick={() =>
                                              data.Status === "waiting"
                                                ? handleCancelWaitingPackage(
                                                    data.GroupId
                                                  )
                                                : handleDataOpen(
                                                    data.GroupId,
                                                    data.PackageId
                                                  )
                                            }
                                          >
                                            {data.Status === "waiting" ? (
                                              <Icons.Close />
                                            ) : (
                                              <Icons.Arrow />
                                            )}
                                          </ActionContainer>
                                        );

                                      case "createtime":
                                        return (
                                          <Fragment>
                                            <TextWrapper
                                              data-tooltip-id={`createtime-${data.GroupId}`}
                                            >
                                              {data[item.value]}
                                            </TextWrapper>
                                            <Tooltip
                                              id={`createtime-${data.GroupId}`}
                                              place="bottom-start"
                                              content={data[item.value]}
                                              style={{
                                                backgroundColor: "#151944",
                                                color: "#fcfcfc",
                                              }}
                                            />
                                          </Fragment>
                                        );

                                      default:
                                        return (
                                          <TextWrapper>
                                            {data[item.value]}
                                          </TextWrapper>
                                        );
                                    }
                                  })()}
                                </Td>
                              ))}
                      </Tr>

                      {data.IsOpen && (
                        <DetailContainer>
                          {data.Status === "running" && (
                            <Fragment>
                              {data.IsWaitingTransfer ? (
                                <TransferButtonWrapper
                                  onClick={() =>
                                    handlePreviewTransferPackage(data.GroupId)
                                  }
                                >
                                  <Lang
                                    datakey="preview"
                                    defaultText="Preview"
                                  />
                                </TransferButtonWrapper>
                              ) : (
                                <div>
                                  <TransferButtonWrapper
                                    onClick={() =>
                                      handleSelectTransferPackage(data.GroupId)
                                    }
                                  >
                                    <Lang
                                      datakey="transfer"
                                      defaultText="Transfer"
                                    />
                                  </TransferButtonWrapper>

                                  {[38, 39, 40, 41, 42, 43].includes(data.PackageId) && (
                                    <WPButton onClick={() => WPbuttonHandler(data.GroupId)}>
                                      {language === "En" ? "Dynamic Close" : "動態結算"}
                                    </WPButton>
                                  )}

                                </div>
                              )}
                            </Fragment>
                          )}

                          <DetailContent>
                            {data.PackageId !== 38 &&
                              data.PackageId !== 39 &&
                              data.PackageId !== 40 &&
                              data.PackageId !== 41 &&
                              data.PackageId !== 42 && (
                                <DetailContentContainer>
                                  <MiningLineChart
                                    chartData={chartData}
                                    chartLabel={chartLabel}
                                    chartName={data.PackageName}
                                  />

                                  <ProfitWrapper>
                                    <Profit>
                                      <ProfitTitle>
                                        <Lang
                                          datakey="cumulative_profit"
                                          defaultText="Cumulative Profit"
                                        />
                                      </ProfitTitle>

                                      <ProfitValueWrapper>
                                        <ProfitAmountWrapper>
                                          <ProfitSymbol>$</ProfitSymbol>
                                          <ProfitAmount>
                                            {AccumulatedProfit(chartData)
                                              .map((data) => data.data)
                                              .reduce(
                                                (accumulator, currentValue) =>
                                                  accumulator + currentValue,
                                                0
                                              )
                                              .toFixed(2)}
                                          </ProfitAmount>
                                        </ProfitAmountWrapper>

                                        <ProfitPercentageWrapper>
                                          <ArrowUpIcon>
                                            <BsFillArrowUpCircleFill />
                                          </ArrowUpIcon>
                                          <ProfitPercentageValueContent>
                                            <ProfitPercentageValue>
                                              {AccumulatedProfitPercentage(
                                                chartData
                                              )
                                                .map((data) => data.data)
                                                .reduce(
                                                  (accumulator, currentValue) =>
                                                    accumulator + currentValue,
                                                  0
                                                )
                                                .toFixed(2)}
                                            </ProfitPercentageValue>
                                            <ProfitSymbol>%</ProfitSymbol>
                                          </ProfitPercentageValueContent>
                                        </ProfitPercentageWrapper>
                                      </ProfitValueWrapper>
                                    </Profit>
                                    <Profit>
                                      <ProfitTitle>
                                        <Lang
                                          datakey="three_month_profit"
                                          defaultText="Three-Month Profit"
                                        />
                                      </ProfitTitle>
                                      <ProfitValueWrapper>
                                        <ProfitAmountWrapper>
                                          <ProfitSymbol>$</ProfitSymbol>
                                          <ProfitAmount>
                                            {ThreeMonthAgoAccumulatedProfit(
                                              chartData
                                            )
                                              .map((data) => data.data)
                                              .reduce(
                                                (accumulator, currentValue) =>
                                                  accumulator + currentValue,
                                                0
                                              )
                                              .toFixed(2)}
                                          </ProfitAmount>
                                        </ProfitAmountWrapper>

                                        <ProfitPercentageWrapper>
                                          <ArrowUpIcon>
                                            <BsFillArrowUpCircleFill />
                                          </ArrowUpIcon>
                                          <ProfitPercentageValueContent>
                                            <ProfitPercentageValue>
                                              {ThreeMonthAgoAccumulatedProfitPercentage(
                                                chartData
                                              )
                                                .map((data) => data.data)
                                                .reduce(
                                                  (accumulator, currentValue) =>
                                                    accumulator + currentValue,
                                                  0
                                                )
                                                .toFixed(2)}
                                            </ProfitPercentageValue>
                                            <ProfitSymbol>%</ProfitSymbol>
                                          </ProfitPercentageValueContent>
                                        </ProfitPercentageWrapper>
                                      </ProfitValueWrapper>
                                    </Profit>
                                  </ProfitWrapper>
                                </DetailContentContainer>
                              )}

                            {/* 僅有PackageId = 38 39 40 41 42 才顯示幣種增量區塊 */}
                            {(data.PackageId === 38 ||
                              data.PackageId === 39 ||
                              data.PackageId === 40 ||
                              data.PackageId === 41 ||
                              data.PackageId === 42) && (
                              <MiningCoinFuture
                                packageId={data.PackageId}
                                groudId={data.GroupId}
                              />
                            )}
                            {/* <MiningCoinFuture
                              packageId={data.PackageId}
                              groudId={data.GroupId}
                            /> */}
                          </DetailContent>

                          <DetailButtonWrapper
                            onClick={() => setHistoryModalIsOpen(true)}
                          >
                            <Lang datakey="detail" defaultText="detail" />
                          </DetailButtonWrapper>
                        </DetailContainer>
                      )}
                    </TbodyContainer>

                    <MiningTableRwd
                      data={data}
                      language={lang}
                      transferDeposit={transferDeposit}
                      transferMining={transferMining}
                      handleEditName={handleEditName}
                      handleChangeReturnType={handleChangeReturnType}
                      handleTxid={handleTxid}
                      handleCancelWaitingPackage={handleCancelWaitingPackage}
                      handleDataOpen={handleDataOpen}
                      handleButtonShow={handleButtonShow}
                      setHistoryModalIsOpen={setHistoryModalIsOpen}
                      transferUsdt={transferUsdt}
                      handlePreviewTransferPackage={
                        handlePreviewTransferPackage
                      }
                      handleSelectTransferPackage={handleSelectTransferPackage}
                      WPbuttonHandler={WPbuttonHandler}
                    />
                  </Fragment>
                ))}
            </Tbody>
          </TableEl>
        ) : (
          <TableEl>
            <NoDataWrapper>
              <Lang datakey="nodata" defaultText="No data available" />
            </NoDataWrapper>
          </TableEl>
        )}
      </TableWrapper>
      {historyModalIsOpen && (
        <MiningHistory
          data={chartData}
          initData={chartDataInit}
          date={chartLabel}
          returnType={returnType}
          isOpen={historyModalIsOpen}
          handleOpen={setHistoryModalIsOpen}
          transferUsdt={transferUsdt}
        />
      )}
      {previewData.length > 0 && previewModalIsOpen && (
        <Preview
          previewData={previewData}
          setPreviewModalIsOpen={setPreviewModalIsOpen}
        />
      )}
    </Wrapper>
  );
};

export default MiningTable;
