import styled, { css } from "styled-components";
import { RWD_SM } from "../../../utils/rwd";
import { useState, useEffect } from "react";
import Progress from "./Progress";
import {
  TaiwanPhoneFormatCheck,
  HongKongPhoneFormatCheck,
} from "../../../utils";
import { useSetPasswordMutation } from "../../../services/authApi";
import { handleParseLanguage } from "../../../utils";

import { SuccessMySwal } from "../../../global/MySwal";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "../../../../components/Router";
import Loading from "../../../components/Loading/Loading";
import { getLanguage } from "../../../services/common";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const Container = styled.div`
  width: clamp(280px, 40vw, 500px);
  flex: 1;
  display: flex;
  flex-direction: column;

  ${RWD_SM(css`
    width: 85vw;
  `)};
`;

const Title = styled.h2`
  font-size: clamp(22px, 1.822vw, 32px);
  font-weight: 600;
`;

const Info = styled.div`
  margin-bottom: 20px;
  font-size: clamp(14px, 0.833vw, 16px);
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const InputEl = styled.input`
  color: #fcfcfc;
  padding: 10px 10px;
  width: 100%;

  color: #fcfcfc;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
  padding: 10px 10px;
  width: 100%;
  /* background: rgba(255, 255, 255, 0.1); */
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );

  &::placeholder {
    font-size: clamp(14px, 0.833vw, 16px);
  }
`;

const LabelEl = styled.label`
  /* font-size: 20px; */
`;

const FormEl = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const HrEl = styled.hr`
  border-color: rgba(255, 255, 255, 0.05);
  margin: 20px 0;

  ${RWD_SM(css`
    margin: 5px 0;
  `)};
`;

const PhoneWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 15px;
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 35%;
`;

const SelectEl = styled.select`
  color: #fcfcfc;
  background: transparent;
  padding: 10px;
  width: 95%;
  font-size: clamp(13px, 0.833vw, 16px);

  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );

  &:focus {
    outline: none;
  }
`;

const ErrorMsg = styled.div`
  font-size: clamp(14px, 0.833vw, 15px);
  color: #f05d2f;
  height: 20px;
`;

const PhoneAndReferralCode = ({
  account,
  password,
  step,
  stepCount,
  handleNextStep,
}) => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phone, setPhone] = useState("");
  const [referenceCode, setReferenceCode] = useState("");
  const [redirect, setRedirect] = useState("");

  // 推薦碼導頁
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    setReferenceCode(query.get("invites"));
    setRedirect(query.get("redirect"));
  }, [search]);

  const phoneCheck = (phone) => {
    const phoneChecks = {
      886: TaiwanPhoneFormatCheck,
      852: HongKongPhoneFormatCheck,
    };
    const checkFunc = phoneChecks[countryCode];
    return checkFunc ? checkFunc(phone) : false;
  };

  const isValid = countryCode && phone && phoneCheck(phone);

  const [_setPasswordAndPhone, { isSuccess, isError, error, isLoading }] =
    useSetPasswordMutation();

  const handleSetPasswordAndPhone = async (e) => {
    e.preventDefault();

    await _setPasswordAndPhone({
      body: {
        Account: account,
        Password: password,
        ReferenceCode: referenceCode ?? "",
        Phone: `+${countryCode}${
          phone.startsWith("0") ? phone.slice(1) : phone
        }`,
      },
    });
  };

  useEffect(() => {
    if (phone || referenceCode || countryCode) setErrorMsg("");
  }, [phone, referenceCode, countryCode]);

  useEffect(() => {
    if (isSuccess) {
      SuccessMySwal({
        title: handleParseLanguage("registration_successful"),
        text: handleParseLanguage("register_suggestion"),
        showCancelButton: true,
        cancelButtonText: handleParseLanguage("login"),
        confirmButtonText: handleParseLanguage("verify_now"),
      }).then((result) => {
        if (result.isConfirmed) {
          handleNextStep();
        } else {
          if (redirect) {
            navigate({
              pathname: `${routes.login_v2}?redirect=${redirect}`,
            });
          } else {
            navigate(routes.login_v2);
          }
        }
      });
    }

    if (isError) {
      setErrorMsg(
        error?.data?.ResultLanguages?.[getLanguage()] || error?.data?.Message
      );
    }
  }, [isSuccess, isError, isLoading]);

  return (
    <>
      <Wrapper>
        {isLoading && <Loading />}
        <Container>
          <Title>{handleParseLanguage("register_phone_title")}</Title>
          <Info>{handleParseLanguage("register_phone_directions")}</Info>
          <FormEl>
            <FormContainer>
              <PhoneWrapper>
                <SelectWrapper>
                  <SelectEl
                    placeholder={handleParseLanguage("country_code")}
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                  >
                    <option disabled value="">
                      {handleParseLanguage("country_code")}
                    </option>
                    <option value="886">+886</option>
                    <option value="852">+852</option>
                  </SelectEl>
                </SelectWrapper>
                <InputWrapper>
                  <InputEl
                    type="text"
                    placeholder={handleParseLanguage("phone")}
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                </InputWrapper>
              </PhoneWrapper>
            </FormContainer>

            <FormContainer>
              <HrEl />
              <Info>
                {handleParseLanguage("register_referralcode_directions")}
              </Info>
              <InputWrapper>
                <InputEl
                  type="text"
                  placeholder={`${handleParseLanguage(
                    "referral_code"
                  )}（${handleParseLanguage("optional")}）`}
                  onChange={(e) => setReferenceCode(e.target.value)}
                  value={referenceCode}
                />
              </InputWrapper>
              <ErrorMsg>{errorMsg}</ErrorMsg>
            </FormContainer>
          </FormEl>
        </Container>
      </Wrapper>
      <Progress
        stepCount={stepCount}
        step={step}
        isValid={isValid}
        handleAction={handleSetPasswordAndPhone}
      />
    </>
  );
};

export default PhoneAndReferralCode;
